export default {
    utilization_info_not_avaliable: "Utilization info not avaliable",
    time_ago: "Time ago",
    upward_topology: "Upward topology",
    link_list: "Links list",
    links_with_highload: "Count highload links",
    only_high_utilization: "Only high utilization",
    topology: "Topology",
    maps: "Maps",
    onts_with_bad_signals: "ONTs with bad signal",
    only_bad_signals: "Only with bad signals",
    is_internal_rule: `
     This is an internal rule  <br>
     <small>This rule is configurable through other parameters and component settings</small>
    `,
    src_iface: "Source iface",
    dest_iface: "Dest iface",
    uplinks: "Uplinks",
    downlinks: "Downlinks",
    positions_success_saved: "Positions success saved",
    auto_reload: "Auto reload",
    open_parameters: "Open parameters",
    enable_fullscreen: "Enable fullscreen",
    unknown_interface: "Unknown interface",
    utilization: "Utilization",
    link_speed: "Link speed",
    configure_your_topology: "Configure topology visualization",
    minimal_link_utilization: "Show links with utilization more %",
    enable_physics: "Enable physics",
    save_object_positions: "Save object positions",
    save_position: "Save position",
    hide_devices_without_links: "Hide devices without links",
    hide_level_above: "Hide devices with level above",
    util_time_period: "Time period",
    no_links: "No links",
    no_links_small_info: "There are no uplinks. To add an uplink - careful editing of the device",
    adjacent_interfaces: "Adjacent interfaces",
    not_found_adjacent_interfaces: "Not found adjacent interfaces",
    any: "Any",
    active: "Active",
    not_active: "Not active",
    signal_type: "Signal type",
    no_data_to_show: "No data to show",
    iface_history_table: "Interface history table",
    uptime_time: "Uptime time",
    days_short: "d.",
    has_errors: "Has errors",
    has_modules: "Has modules",
    traps: {
      name: "SNMP Traps",
      export: "Export trap",
    },
    object: "Object",
    raw_data: "Raw data",
    parsed_oids: "Parsed raw data",
    no_interface: "No interface",
    start_write_for_filter: "Start write for filter...",
    object_filter: "Object filter",
    log_traps: "SNMP Traps",
    srv_profile: "Service-profile",
    not_found_session_in_billing: "Not found active session in billing ({error}) ",
    not_found_in_billing: "Not found information in billing",
    pon_box: "PON box",
    cvlan: "CVLAN",
    dhcpoption: "DHCP option",
    house: "h.",
    apartment_short: "ap.",
    open_billing: "Open billing",
    fio: "FIO",
    export_excel: "Export to excel",
    temperature_chart: "System temperature chart",
    google_twofa_pin_instruction: "Please enter the 6-digit code received in the application for authorization",
    choose_device: "Choose device",
    choose_interface: "Choose interface",
    onts_not_found: "ONTs not found on device<br>(or have some errors on load)",
    info: "Info",
    select: "Select",
    onts_registration: {
        registration_form: "Registration form",
        preview: "Preview commands to execute",
        exec: "Result of registration",
        onu_success_registered_searching: "ONT registered! \nSearching...",
        onu_success_registered_and_found: "ONT success registered and found!",
        registered_but_not_found: "ONT registered, but not found automatically. Please, check logs or find manually",
        reload_variables_from_device: "Reload variables from device",
        registration_template_not_configured_for_model: "<span style='color: darkred'>Not found registration macro for current model, registration not possible <br>Please, configure registration</span>",
        please_choose_unregistered_ont: "Please, choose unregistered ONT",
        config: "ONTs registration",
        list: "List macros for ONTs registration",
        import: "Import macro",
        add: "Add macro",
        edit: "Edit macro",
        remove: "Remove macro",
        export: "Export macro (json)",
        edit_macros: "Edit macros '{name}'",
        model_vendor: "Device vendor",
        models: "Device models",
        device: "Device",
        confirm_remove: "Are you sure to remove template?",
        success_deleted: "Success deleted",
        success_created: "Success created",
        fail_updated: "Fail to update macros",
        success_updated: "Success updated",
        add_new: "Add new macros",
        unregisteredOnts: "Unregistered ONT",
        error_read_imported_macros: "Error load imported macros",
    },
    pinger_info: "Pinger info",
    increasing_errors_by_day: "Increasing errors by last 24 hours",
    step: "step",
    increasing_errors: "Increasing errors",
    chart: "Chart",
    increasing_errors_for_iface: "Increasing errors graph for interface {iface}",
    counter_in_errors: "IN errors",
    counter_out_errors: "OUT errors",
    increasing_in_errors: "Inreasing IN-errors",
    increasing_out_errors: "Inreasing OUT-errors",
    copy: "Copy",
    copied: "Copied",
    twofa_confirm: "Do you really want to disconnect 2FA?",
    twofa: "2FA",
    '2fa_disconnected': "2FA disconnected",
    '2fa_connected': "2FA connected",
    twofa_disconnect: "Disconnect",
    twofa_please_enter_pin: "Please, write PIN-code",
    twofa_instruction: "Scan the code using the <a target='_blank' href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ru&gl=US&pli=1'>Google Authenticator</a>, enter the received code and click save",
    twofa_manual: "Or enter manual code",
    twofa_connect: "Enable",
    twofa_connecting: "Enabling 2FA",
    twofa_connect_submit: "Submit",
    wrong_pin: "Pin-code is wrong",
    twofa_connected: "2FA has been enabled",
    connect_2fa: "Scan the QR code with Google Authenticator",
    twofa_pin: "PIN code",
    twofa_enabled: "2FA is active",
    n_a: "N/A",
    macros_not_found: "Macros not found",
    hide_online: "Hide online",
    show_only_favorite: "Only favorite",
    save_fdb_history: "Save FDB to history",
    duration: "Duration",
    availability: "Availability",
    periods: {
        '24h': "1 day",
        '1d': "1 day",
        '3d': "3 days",
        '7d': "7 days",
        '30d': "30 days",
    },
    down_logs: "Down logs",
    packet_loss: "Packet loss",
    online_from: "Online from",
    offline_from: "Offline from",
    durations: {
        year: 'y',
        month: 'mo',
        week: 'w',
        day: 'd',
        hour: 'h',
        minute: 'm',
        seconds: 's',
        millis: 'millis',
    },
    no_pollers: "No pollers",
    lacp_interfaces: "Interfaces in LACP",
    clone: "Clone",
    start_write_for_search: "Searching...",
    start_write_for_search_by: "Searching by...",
    uni_interfaces_vlans: "UNI interfaces vlans",
    ont_registered_but_not_found_automaticaly: "ONT registered, but not found after registration. Try go to ONU manually",
    onts_list: "ONTs list",
    ont_statistic: "ONT statistic",
    traffic_chart: "Traffic chart",
    traffic_live_view: "Traffic live view chart",
    ont_success_rebooted: "ONT success rebooted!",
    are_you_sure_to_reboot_ont: "Are you sure to reboot ONT?",
    ont_success_reset: "ONT success reset!",
    are_you_sure_to_reset_ont: "Are you sure to reset ONT?",
    device_is_offline_big_message: `
    <h4>Device is offline (not respond over SNMP/console)</h4>Some information may be returned from history
    `,
    please_wait_working_with_device: "Please wait, requesting data from the equipment...",
    filters_modal: "Filters",
    show_devices: "Show devices",
    show_onts: "Show onts",
    show_links: "Show links",
    clustering: "Grouping",
    source: "Source",
    destination: "Destination",
    ont_level_strength: "Strength level ONTs",
    duplicated_onts: "Duplicated ONTs",
    duplicated_mac: "Duplicated MAC",
    duplicated_macs: "Duplicated MACs",
    count_duplicates: "Count duplicates",
    duplicates: "Duplicates",
    first: "First",
    last: "Last",
    ifaces_detailed_statistic: "Interface detailed stat",
    interfaces_stat_type: "Display statistic",
    splitted: "Splitted",
    rules_not_found: "Rules not found",
    uni_port_state_success_changed: "UNI port admin state success changed",
    activated_box_license: "BOX license",
    abonent: "Abonent",
    information_not_collected_yet: "Information not avaliable yet, try later",
    open_in_billing: "Open in billing",
    uid: "UID",
    toggle_not_avaliable_becouse_input: "Toggle not avaliable, becouse direction Input",
    analog_lines_list: "Analog lines",
    digital_lines_list: "Digital lines",
    power_control_output_list: "Power controls",
    power_sensor_state: "Power sensor",
    sensors_not_found: "Sensors not found",
    knock_sensor_state: "Knock sensor",
    digital_direction_input: "Input",
    digital_direction_output: "Output",
    low: "Low",
    high: "High",
    power_supply: "Power supply",
    security: "Security",
    water_leak: "Water leak",
    smoke_detector: "Smoke detector",
    direction: "Direction",
    controlled_by_pings: "Controlled by pings",
    controlled_by_analog_line: "Controlled by analog line",
    power_availability: "Power supply",
    seems_sensor_not_supported: "Seems sensor not supported",
    temperature: "Temperature",
    mark_as: "Mark as",
    not_marked: "Not marked",
    no_related_information_to_show: "No related information to show",
    edit_port_state: "Change port state",
    are_you_sure_to_reset_port: "This action may lead to the disabling of the ONU on the interface.\n Are you sure to reset port?",
    port_success_reset: "Port success reset!",
    reset_port: "Reset port",
    card_list: "Cards list",
    card_status: "Cards status",
    oper_status: "Operative status",
    physical_ports_not_found: "Physical ports not found on card",
    big_noty_subscription_limit_reached: `
        <h3>Subscription limit by interfaces is reached!</h3>
        Some functionally, as adding new interfaces, new devices, poll history are not avaliable 
    `,
    big_noty_connect_to_validation_server_failed: `
        <h3>Connect to wildcore validation server is failed!</h3>
        Please, check is server has connection to internet and address api.wildcore.tools is avaliable 
    `,
    favorite_interfaces: "Favorite interfaces",
    tagged_interfaces: "Tagged interfaces",
    favorite_list: "Favorite list",
    error: "Error",
    interface_mark: "Interface marks",
    tags: "Tags",
    local_id: "Local ID",
    open: "Open in wildcore",
    write_list_ip_address_with_cidr_format: "Write allowed IP networks in CIDR format",
    strict_by_ip_access: "Strict access by IP/network",
    enable_strict_ip: "Enable strict access over IP",
    allowed_ip_placeholder: "192.168.0.0/24",
    billing_info: "Billing info",
    contract: 'Contract',
    on: 'On',
    off: 'Off',
    device_not_found_in_oxidized: "Device not found in oxidized",
    last_config: "Last configuration",
    oxidized_configuration: "Oxidized configuration",
    last_status: "Last status",
    start: "Start",
    end: "End",
    spent: "Spent",
    open_oxidized: "Open in oxidized",
    oxidized: "Oxidized",
    backup_oxidized_enabled: "Enable oxidized backups",
    agent_key: "Agent key",
    registered_ip: "Registered IP",
    reserved_interfaces: "Reserved interfaces",
    from: "From",
    allowed_components: "Allowed components",
    version: "Version",
    count_groups: "Count groups",
    usage_statistic: "Usage statistic",
    subscription_info: "Subscription info",
    preview_commands_before_send: "Preview commands sent to OLT",
    interfaces_not_loaded: "Interface not saved yet...",
    are_you_sure_for_clear_poller_history: "Are you sure to clear all information about device and interfaces?",
    success_cleared: "Success cleared",
    clear_history: "Clear poll history",
    ont_statuses: "ONT statuses",
    device_statuses: "Device statuses",
    analytics_click_on_chart_for_load_data: "Click on the desired time to view the table in more detail for the selected time",
    graph_step: "Graph step",
    chart_data: "Chart data",
    analytics: "Analytics",
    rebuild_required_description: `
      <span style="font-weight: bold; color: darkred">*</span>  To apply the changed parameter, you need to rebuild the containers, run command on server <pre>cd /opt/wildcore-dms && sudo docker compose up -d --build</pre>
    `,
    are_you_sure_to_reboot_onu: "Are you sure to reboot ONT?",
    are_you_sure_to_delete_ont: "Are you sure to delete ONT?",
    ont_success_deleted: "ONT success deleted",
    breadcrumb_show: "Show device title",
    has_active_pollers: "Device has active background pollers. Information may take longer to receive.",
    history_is_empty: "Down history is empty",
    public_community: "Public community",
    private_community: "Private community",
    public_community_placeholder: "public",
    private_community_placeholder: "private",
    snmp_version: "SNMP version",
    interfaces_list: "Interfaces list",
    current_channel: "Current channel",
    auth_mode: "Auth mode",
    sn_bind: "SN bind",
    dba_mode: "DBA mode",
    fec: "FEC",
    fec_actual_mode: "FEC actual mode",
    pps1_tod: "PPS1 TOD",
    auto_replace: "Auto replace",
    mcast_encrypt: "Mcast encrypt",
    down_history_table: "Down history table",
    port_loading: "Port loading",
    poller_in_process: "Poller in process",
    reboot_device: "Reboot device",
    save_config: "Save config on device",
    field_name: "Field name",
    ident: "Ident",
    optical_rx: "Optical RX",
    optical_tx: "Optical TX",
    optical_olt_rx: "Optical OLT TX",
    optical_temperature: "Optical temperature",
    optical_distance: "Optical distance",
    no_filter: "No filter",
    poller_data: "Poller data",
    ont_list: "ONT list",
    agreement: "Agreement",
    value: "Value",
    filter_field_name: "Filter field",
    no_data: "No data",
    disable_ont: "Disable ONT",
    bgp_sessions: "BGP sessions",
    lease_info: "Lease info",
    go_to_boxes_list: "Go to boxes list",
    pon_ports_list: "PON ports list",
    pon_ports_optical: "PON ports optical",
    sys_resources: "System resources",
    incorrect_value_for: "Incorrect value for '{value}'",
    supported_modules: "Supported modules",
    interface_not_loaded: "Interface not loaded",
    reboot_ont: "Reboot ONT",
    address_list_info: "Address list info",
    arp_info: "ARPs",
    dhcp_server_info: "DHCP servers",
    leases_info: "Leases",
    simple_queue_info: "Simple queue",
    device_is_offline: "Device is offline",
    device_dash: {
        tabs: {
            history_log: "History log",
            pinger: 'Pinger',
            macros: 'Macros',
            sensors: 'Sensors',
            sensor_configuration: 'Configuration',
            onts_tree: "ONTs tree",
            events: "Events",
            pon_boxes: "Pon boxes",
            unregistered_onts: "Unregistered ONTs",
            deprecated_unregistered_onts: "Unregistered ONTs (deprecated)",
            ports: "Ports",
            interfaces: "Interfaces",
            address_list: "Address list",
            arps: "ARPs",
            leases: "Leases",
            dhcp_servers: "DHCP servers",
            simple_queues: "Simple queues",
            bgp_sessions: "BGP sessions",
            topology: "Topology",
            vlans: "Vlans",
            oxidized: "Config backup",
            card_interfaces: "Interfaces/cards",
        }
    },
    uni_extra_info: {
        type: "Type",
        flow_control_status: "Flow control status",
        pvid_mode: "PVID mode",
        admin_status: "Admin status",
        vlan_id: "Vlan ID",
        vlan_mode: "Vlan mode",
        admin_state: "Admin state",
        duplex: "Duplex",
        speed: "Speed",
        id: "ID",
        name: "Name",
        mode: "Mode",
        pvid: "PVID",
        trunk_vlans: "Trunk vlans",
        uni_interfaces_vlans: "UNI interfaces vlans",
        auto_negotation: "Auto negotation",
        flow_control: "Flow control",
        vlan_pvid: "PVID",
        vlan: "VLAN",
        admin_speed: "Admin speed",
        power_control: "Power control",
    },
    gpon_profiles: "GPON profiles",
    auth_info: "Auth info",
    ont_configuration: "ONT configuration",
    last_dereg: "Last dereg",
    last_dereg_since: "Last dereg since",
    last_reg: "Last registration",
    parse_interface: "Parse interface",
    olt: "OLT",
    ont_status: "ONT status",
    storage_info: "Storage info",
    vendor_info: "Vendor info",
    poll_enabled: "Poll enabled",
    ver_hardware: "Ver hardware",
    ver_software: "Ver software",
    extra_info: "Extra info",
    go_to_events: "Go to events",
    go_to_devices_list: "Go to devices list",
    from_storage: "From storage",
    from_device: "From device",
    device_calling: "Device calling",
    has_configuration: "Has configuration",
    view: "View",
    component_configuration: "Component configuration",
    load_config: "Load config",
    loading_is_very_slow: `Loading is very slow :-( <br><small>Try to reload page</small>`,
    reload_page: "Reload page",
    dashboard_saved: "Dashboard saved",
    events_by_name_stat: "Count events by name (last day)",
    count: "Count",
    device_calling_errors_stat: "Error device calling (last day)",
    errors_count: "Errors count",
    not_responding_count: "Not respond count",
    top_devices_by_errors: "Top device by errors",
    system_stat: "System stat",
    count_device_groups: "Count device groups",
    count_users: "Count users",
    count_roles: "Count roles",
    unregistered_onts_not_found: "Unregistered ONTs not found",
    set_for_all_users: "Set for all users",
    role_successful_updated: "Rule succesful updated",
    user_role: "User role",
    add_widget: "Add widget",
    choose_widget: "Choose widget",
    pinger: "Pinger",
    last_user_activity: "Last user activity",
    events_table: "Events table",
    events_stat: "Events stat",
    online_devices: "Online devices",
    up_interfaces_chart: "UP interfaces charts",
    ont_statuses_pie: "ONT statuses",
    widgets: {
        tags: "Tags",
        favorite_interfaces: "Favorite interfaces",
        high_links_utilization: "Count high links utilization",
        bad_ont_signal: "Count bad ONTs signal",
        ont_statuses_pie: "ONT statuses pie",
        ont_signal_bar: "ONTs signals strength",
        pinger_stat: "Pinger stat",
        events_stat: "Events stat (by severity)",
        events_stat_by_name: "Events stat (by name)",
        last_user_activity: "Last user activity",
        events_table: "Events table",
        unregistered_onts_table: "Unregistered ONTs table (deprecated)",
        unregistered_onts: "Unregistered ONTs table",
        system_stat: "System stat",
        device_calling_errors_stat: "Device calling errors stat",
        device_status_chart: "Device status charts",
        online_onts_chart: "Online ONTs chart",
        up_ifaces_chart: "Online interfaces chart",
    },
    onu_offline_signals_not_avaliable: "ONT is offline, realtime signals info not available",
    live_traffic_view: "Live traffic view",
    update_interval: "Update interval",
    in: "In",
    out: "Out",
    current: "Current",
    avg: "Avg",
    max: "Max",
    enable_ont: "Enable ONT",
    last_change: "Last change",
    omcc_version: "OMCC version",
    model_id: "Model ID",
    versions: "Versions",
    vlan_profile_name: "VLAN profile name",
    mode: "Mode",
    trunk_vlans: "Trunk vlans",
    description_success_updated: "Description success updated",
    ont_success_enable: "ONT success enabled",
    ont_success_disable: "ONT success disabled",
    are_you_sure_to_disable_ont: "Are you sure to disable ONT?",
    are_you_sure_to_enable_ont: "Are you sure to enable ONT?",
    check_uplink: "Check uplink",
    link_types: {
        manual: "Manual",
        lldp: 'LLDP',
        fdb: 'FDB',
    },
    coordinates_not_setted: "Coordinates not setted",
    interfaces_list_success_polled: "Interfaces list success polled",
    not_setted: "Not setted",
    not_choosed: "Not choosed",
    choose_device_is_required: "Dest/Uplink device is required",
    delete_link: "Delete link",
    are_you_sure_delete_link: "Are you sure for delete link?",
    links_not_added: "Links not added",
    link_connection_type: "Link type for current device",
    uplink: "Uplink",
    downlink: "Downlink",
    uplink_device: "Uplink device",
    uplink_interface: "Uplink interface",
    downlink_device: "Downlink device",
    downlink_interface: "Downlink interface",
    snmp_port: "SNMP port",
    choose_device_coordinates_before_start_work_with_device: "You must set device coordinates, before using pon boxes with choosed device",
    phpmyadmin: "phpMyAdmin",
    creating_new_link: "Create new link",
    searching_string: "Searching string",
    choose_devices_for_send_notifications: "Choose devices for send notifications",
    edit_monitoring_devices: "Edit monitoring devices",
    edit_notification_devices: "Edit monitoring devices",
    address_not_found: "Address not found",
    click_to_button_map_for_set_coordinates: "Click to button for set coordinates in popup",
    choose_coordinate: "Choose coordinate",
    display_by_status: "Highlight by status",
    by_status: "Status",
    no_mon_interfaces: "No monitoring",
    count_mon_interfaces: "Count monitoring ONTs",
    location_from_coordinates: "Coordinates",
    add_new_box: "Add new box",
    create_new_box: "Box adding",
    template_info: "Template info",
    template_id: "Template ID",
    count_boxes: "Count boxes",
    count_links: "Count links",
    template: "Template",
    choose_onts_for_monitoring: "Choose ONTs for monitoring",
    mon_onts: "Monitoring over ONTs",
    to_boxes: "To boxes list",
    view_on_map: "View on map",
    box_id: "Box ID",
    box_info: "Box info",
    links: "Links",
    success_saved: "Success saved!",
    are_you_sure_to_delete_box: "Are you sure for delete box?",
    are_you_sure_delete_element: "Are you sure for delete element?",
    are_you_sure_to_delete_link: "Are you sure for delete link?",
    edit_box_with_name: "Editing box with {name}",
    neighbor: "Neighbor",
    editing_link_with_id: "Editing link with ID={id}",
    mon_interfaces: "Count monitoring ONTs",
    add_link: "Add link",
    coordinates: "Coordinates",
    type_or_name_not_setted_object_deleted: "Type or name not setted, object creation will be removed",
    pon_boxes: {
        name: 'PON boxes',
        list: 'Boxes list',
        map: 'Map',
        types: 'Config types',
        map_edit: "Map editing",
        map_builder: "Map editing",
        tabs: {
            boxes: 'Box types',
            links: 'Link types',
        },
    },
    edit_type: 'Edit type',
    general: 'General',
    styling: 'Styling',
    size: 'Size',
    color: 'Color',
    stroke_size: 'Stroke size',
    stroke_color: 'Stroke color',
    box_types: {
        tabs: {
            boxes: 'Boxes',
            links: 'Links',
        }
    },
    add_element: 'Add element',
    map: 'Map',
    edit_box: 'Edit box',
    box_name: 'Box name',
    monitoring_onts: 'ONTs for monitoring',
    control: 'Control',
    edit_link: 'Edit link',
    element_success_created: 'Element success created',
    for_control_choose_device_and_interface: 'Choose device and interface for add control ONTs',
    error_choose_device_must_contain_coordinates: 'Error choose current device. Device must contain coordinates',
    poller_not_worked_yet: "Poller not worked yet",
    start_poller_requested: "Run poller requested",
    error_loading_info: "Error loading information",
    global: "Global",
    notification_place: "Notifications place",
    noty_places: {
        topLeft: "Top Left",
        topRight: "Top Right",
        topCenter: "Top Center",
        center: "Center",
        centerLeft: "Center Left",
        centerRight: "Center Right",
        bottom: "Bottom",
        bottomLeft: "Bottom Left",
        bottomCenter: "Bottom Center",
        bottomRight: "Bottom Right",
    },
    vlan_pvid: "VLAN Pvid",
    vlan_mode: "VLAN Mode",
    auto_negotation: "Auto negotation",
    flow_control: "Flow control",
    dhcp_snooping: "DHCP Snooping",

    show_groups: "Show groups",
    delete_access: "Delete access",
    edit_access: "Edit access",
    old_agent_version_reload_your_page: "<h3>Old webpanel version. Please, reload your page (CTRL+F5)</h3>",
    agent_is_disabled: "Agent is disabled. Please, contact with support@wildcore.tools",
    incorrect_field_must_be_regex: "Incorrect input, must be - '{regexp}'",
    config_zte_onts_registration: "ZTE ONT reg (deprecated)",
    config_huawei_onts_registration: "Huawei ONT reg (deprecated)",
    onu_success_sended_to_reboot: "ONT success sended to reboot",
    distantion: "Distantion",
    last_reg_since: "Last reg since",
    detailed_info: "Detailed info",
    ignore_actions: "Ignore actions",
    onu_success_deregistrated: "ONT success deregistered",
    are_you_sure_to_dereg_onu: "Are you sure to dereg ONT?",
    rule_success_validated: "Rule success validated",
    error_in_poller_by_day: `Error in poller<br><small>by day</small>`,
    show_pinger_info: "Show pinger info",
    delete_role: "Delete role",
    validate_rule: "Validate rule",
    add_action_for_notification: "Add action for notification",
    portal_settings: "Portal settings",
    show_error_counters: "Show error counters",
    show_events: "Show events",
    show_pinger_inf: "Show pinger info",
    show_unregistered_onts: "Show unregistered ONTs",
    expand_pon_tree: "Expand PON tree",
    device_dashboard: "Device dashboard",
    down_devices_on_top: "Down devices on top",
    sort_by: "Sort by",
    your_role: "You role",
    notifications_configuration: "Notifications configuration",
    log_poller: "Poller logs",
    pollers: "Pollers",
    poller: "Poller",
    default_pollers: "Default pollers",
    poller_configuration: "Poller configuration",
    use_default_poll_configuration: "Use default poller configuration",
    poller_name: "Poller name",
    interval: "Interval",
    configure_port_polling: "Configure port polling",
    count_interfaces: "Count interfaces",
    count_polled: "Count polled interfaces",
    edit_ports: "Edit ports",
    bind_key: "Bind key",
    enable_polling: "Enable polling",
    edit_interface_polling: "Edit interface polling",
    interfaces_list_not_loaded: "Interfaces list not loaded",
    poll_interfaces: "Poll interfaces",
    run_poller: "Run poller",
    roles: "Roles",
    user_role_list: "User's roles",
    create_new_role_btn: "Create new role",
    role_permissions_count: "Role permissions count",
    edit_role: "Edit role",
    role_privileges: "Role privileges",
    create_new_role: "Create new role",
    role_successful_created: "Role success created",
    are_you_sure_for_delete_role: "Are you sure for delete role?",
    role_success_deleted: "Role success deleted",
    role: "Role",
    configure_notifications_by_events: "Configure notifications create by events",
    configure_notifications_by_actions: "Configure notifications create by actions",
    add_event_for_notification: "Add event for notification",
    event_name: "Event name",
    send_resolved: "Send resolved",
    delay_before_send: "Delay before send",
    action_name: "Action name",
    send_on_status_failed: "Send on status failed",
    send_on_status_success: "Send on status success",
    action_notification: "Notification",
    event_configuration: "Event configuration",
    group_name: "Group name",
    for: "For",
    alert_name: "Alert name",
    expression: "Expression",
    annotation_summary: "Annotation summary",
    annotation_description: "Annotation description",
    notification_configuration_success_saved: "Notification configuration success saved",
    add_rule: "Add rule",
    events: {
        name: "Events",
        tabs: {
            alertmanager: "Alertmanager",
        }
    },
    rules_success_saved: "Rules success saved",
    notifications: {
        contact_telegram_message: ` 
        <div style="margin-top: 10px">
        You can get chat telegram ID over bot <a href="https://t.me/myidbot" target="_blank">@IDbot</a> and fill telegram chat id here<br>
        <div style="text-align: center; font-weight: bold">OR</div>
        <div  style="">
            <li>Add your phone for telegram</li>
            <li>Go to you own bot - <a href="{url}" target="_blank">@{name}</a> and send command /start. You chat ID will be added automaticaly</li>
        </div>
        </div>
    `,
        phone_for_telegram_descriptions: `
            <br><h4>Current phone number will be use only for registration in telegram bot</h4>
        `,
        types: {
            EMAIL: "Email",
            TELEGRAM_ID: "Telegram",
            PHONE_FOR_TELEGRAM: "Phone for telegram",
            PHONE: "Phone",
            telegram_chat_id: "Telegram chat ID",
        },
        channels_not_configured: {
            email: `Sending over email not configured. You can add contact, but it won't be used for sending messages`,
            phone: `Phone number used for registration in telegram bot`,
            telegram: `Telegram not configured`,
        },
        tabs: {
            events_config: "Events rules",
            actions_config: "Actions rules",
            email: "Sending channel - Email",
            telegram: "Sending channel - Telegram",
        },
        severities: {
            NOTIFICATION: "Notify",
            INFO: "Info",
            WARNING: "Warning",
            CRITICAL: "Critical",
        },
    },
    sfp_diag: "Diag SFP",
    speed: 'Speed',
    duplex: 'Duplex',
    uni_interfaces_status: "UNI interfaces status",
    pon_onts_down_history: "ONT down history",
    pon_onts_configuration: "ONT configuration",
    interface_counters: "Counters on interface",
    conf_status: "Conf status",
    auth_method: "Auth method",
    isolation: "Isolation",
    attributes: "Attributes",
    down_reason: "Down reason",
    web: "Web",
    telnet: "Telnet",
    ssh: "SSH",
    system: "System",
    ports: "Ports",
    diag: "Diagnostic",
    sfp_info: "SFP Info",
    media_info: "Media info",
    tx_bias: "TX bias",
    serial_num: "Serial num",
    connector_type: "Connector type",
    fiber_type: "Fiber type",
    eth_compliance_codes: "Eth compliance codes",
    baud_rate: "Baud rate",
    vendor_name: "Vendor name",
    part_number: "Part number",
    networks_success_saved: "Networks success saved!",
    are_you_sure_to_delete_element: "Are you sure to delete element?",
    autodiscovery_networks_not_found: "Not found autodiscovery networks configuration",
    add_network: "Add network",
    network_cidr: "Network CIDR",
    device_access: "Device access",
    add_to_device_group: "Add new devices to group",
    autodiscovery_device_access_is_required: "Device access is required! You must choose usage access for network {network}",
    autodiscovery_device_group_is_required: "Device group is required! You must choose device group for network {network}",
    config_autodiscovery: "Autodiscovery",
    welcome_to_nms: "Welcome!",
    welcome_message: `
<h3 class="text-info">Welcome to web panel <b>WildCore DMS</b>!</h3>
            <div>
              <h5 class="text-info">Evaluate the ability to diagnose and monitor equipment</h5>
              <ol>
                <li>Add your first hardware access (community/login/password) <a href="/management/device-access">here</a>
                </li>
                <li>Add your first device  <a href="/management/device">here</a>
                </li>
              </ol>
            </div>
            <div>
              <h5 class="text-info">Set up notifications to Telegram or email</h5>
              <ol>
                <li>Customize how notifications are sent - <a href="/config/notifications">Notifications settings</a>
                </li>
                <li>Add your contacts to receive notifications  -  <a href="/account/settings">Account settings</a>
                </li>
              </ol>
            </div>
            <div>
              <h5 class="text-info">Grant access to wildcoreDMS to colleagues</h5>
              <ol>
                <li>Create a new users group, give them the access they need - <a href="/management/user-group">Groups</a>
                </li>
                <li>Add new users - <a href="/management/user">Users</a>
                </li>
              </ol>
            </div>    
    `,
    bind_status: "Bind status",
    olt_rx: "OLT RX",
    onu_statistic: "ONU statistic(counters)",
    pon_onts_reasons: "Down reasons",
    discards: "Discards",
    multicast: "Multicast",
    broadcast: "Broadcast",
    pon_onts_status: "PON onts status",
    interface_descriptions: "Interface descriptions",
    pon_onts_vendor: "Vendor info",
    used_default_connections_parameters: "Used default connections parameters",
    change_default_connection_parameters: "Set custom connection parameters",
    set_default_connection_parameters: "Set default connection parameters",
    connection_connection_type: "Connection type",
    port: "Port",
    timeout_sec: "Timeout (sec)",
    snmp_timeout_sec: "SNMP timeout (sec)",
    snmp_repeats: "SNMP repeats",
    labels: "Labels",
    ignore_events: "Ignore events",

    add_contact: "Add contact",
    edit_contact: "Edit contact",
    are_you_sure_delete_contact: "Are you sure for delete contact?",
    configured_sources_not_found: `
        Not found configured sources<br>
        <small>Please, configure sources before</small>
    `,
    telegram_bot_configuration: "Telegram bot configuration",
    configuration_success_updated: "Config success updated",
    reset: "Reset",
    telegram_bot_name: "Bot name",
    telegram_bot_api: "Bot API key",
    smtp_host: "SMTP host",
    your_bot_username: "Your bot username",
    your_api_key: "Your API key",
    smtp_port: "SMTP port",
    smtp_secure: "Use secure",
    smtp_username: "SMTP username",
    smtp_password: "SMTP password",
    from_name: "From name",
    from_email: "From email",
    template_configuration: "Template configuration",
    alert_template: "Alert template",
    alert_resolved: "Resolved template",
    mock_object: "Mock object",
    alerts_configuration: "Notifications configuration",
    smtp_configuration: "SMTP configuration",
    cpu_util_chart: "CPU utilization chart",
    memory_util_chart: "Memory utilization chart",
    disk_util_chart: "Disk utilization chart",
    no_more_events: "No more events",
    no_events: "No events",
    severities: "Severities",
    only_not_resolved: "Not resolved",
    severity: "Severity",
    resolved_at: "Resolved at",
    names: "Names",
    created_at: "Created at",
    not_resolved: "Not resolved",
    no_device: "No device",
    are_you_sure_for_resolve_event: "Are you sure to resolve event with id={id}?",
    are_you_sure_for_resolve_all_filtered_events: "Are you sure to resolve all events by filter?",
    events_success_resolved: "Success resolved {count} events",
    resolve: "Resolve",
    event_success_resolved: "Event with id={id} success resolved",
    resolve_displayed: "Resolve filtered",
    grafana: "Grafana",
    alertmanager: "Alertmanager",
    prometheus: "Prometheus",
    external_apps: "External apps",
    models: "Models",
    icon: "Icon",
    controller: "Controller",
    edit_model: "Edit model {name}",
    device_model_success_updated: "Model {name} success updated",
    forbidden_resource: "Access forbidden",
    forbidden_resource_descr: "Please contact with your administrator for access",
    agent_disabled: "No active subscription/agent is disabled, please contact support@wildcore.tools",
    no: "No",
    yes: "Yes",
    limit_at: "Limit At",
    max_limit: "Max Limit",
    dynamic: "Dynamic",
    target: "Target",
    reload: "Reload",
    traffic: "Traffic",
    last_link_up_time: "Last link up time",
    interface_name: "Interface name",
    comment: "Comment",
    remote_address: "Remote addr",
    remote_as: "Remote AS",
    local_address: "Local addr",
    established: "Established",
    host_name: "Host name",
    server: "Server",
    lease_time: "Lease time",
    up: "Up",
    down: "Down",
    address: "Address",
    expired_at: "Expires as",
    address_pool: "Address pool",
    router_os: {
        tabs: {
            interfaces_list: "Interfaces",
            arps: "ARPs",
            bgp_sessions: "BGP-sessions",
            address_lists: "Address lists",
            leases: "Leases",
            dhcp_servers_list: "DHCP-servers",
            simple_queue_list: "Simple queue",
        }
    },
    chart_translations: {
        Online: "Online",
        Offline: "Offline",
        PowerOff: "PowerOff",
        LOS: "LOS",
        up: 'Up',
        down: 'Down'
    },
    pinger_host_stat: "Devices status (ICMP)",
    optical_level_chart: "Optical level strength",
    displayed_with_step: "Displayed with step",
    temp_chart: "Temperature graphic",
    voltage_chart: "Voltage graphic",
    last_6_hours: "Last 6 hours",
    last_12_hours: "Last 12 hours",
    last_24_hours: "Last 24 hours",
    last_3_days: "Last 3 days",
    last_7_days: "Last 7 days",
    last_14_days: "Last 14 days",
    last_21_days: "Last 21 days",
    last_30_days: "Last 30 days",
    choose_group_is_required: "Choose group is required!",
    filter: 'Filter',
    expand_all: 'Expand all',
    hide_all: 'Hide all',
    search_query: 'Search query',
    device_models: 'Device models',
    clear_filters: 'Clear filters',
    device_groups: "Device groups",
    group_success_created: 'Group success created!',
    group_success_updated: 'Group success updated!',
    cleared: 'Cleared!',
    saved: 'Saved!',
    device_group_management: "Group device management",
    create_new_group_btn: "Add new group",
    edit_group_with_id: "Edit group with id {id}",
    checked: "Checked",
    parameters_is_empty_are_you_sure: "Parameters is empty! Are you sure?",
    history_not_found: "History not found",
    fdb_history: "FDB history - {interface}",
    fdb_history_card: "FDB history",
    active_now: "Active now",
    key: "Key",
    schedule_reports: "Schedule reports",
    schedule_keys: "Schedule keys",
    hide_finished: "Hide finished",
    schedule: "Schedule",
    started_at: "Started at",
    finished_at: "Finished at",
    schedule_configuration: "Schedule configuration",
    last_run: "Last run",
    component: "Component",
    crontab: "Crontab",
    command: "Command",
    state: "State (enabled/disabled)",
    system_cron: "SYSTEM",
    schedule_updated: "Schedule updated",
    language: "Language",
    your_login: "Your login",
    id: "ID",
    your_group: "Position(group)",
    choose_model_is_required: "Model is required",
    choose_access_is_required: "Access is required",
    ip_address_not_valid: "Incorrect IP address",
    default_value: "Default value",

    system_configuration: "System configuration",
    error_load_fdb: "Error load FDB",
    pon_onts_serial: "Serial number",
    location: "Location",
    load_info_from_device: "Get info from device",
    start_at: "Start At",
    stop_at: "Stop At",
    unknown: "unknown",
    error_get_data_check_in_logs: "Error load info, see logs of device calling for details",
    on_device: "on device",
    ont_ident: "ONT ident",
    mac_in_fdb: "MAC in FDB",
    device_is_offline_and_history_not_found: "Device is offline and history not found",
    edit_port: "Configure port {port}",
    interface_info: "About interface",
    admin_speed: "Admin speed",
    vlans_by_port: "Vlans on port",
    rmon: "RMON",
    port_success_updated: "Port {port} success updated!",
    diag_check: "Check",
    link_info: "Link info",
    vlans: "Vlans",
    errors: "Errors",
    clear_counters: "Clear counters",
    reboot: "Reboot",
    are_you_sure_to_reboot_device: "Are you sure to reboot device?",
    are_you_sure_to_clear_counters: "Are you sure to clear counters?",
    counters_success_reset: "Counters success cleared!",
    device_success_sended_to_reboot: "Success sended device to reboot",
    fdb: "FDB",
    cable_diag: "Cable diag",
    port_num: "# port",
    vlan: "Vlan",
    counters: "Traffic",
    stat: "Statistic",
    uni_ports: "UNI ports",
    are_you_sure_to_reset_onu: "Are you sure to reset ONT?",
    are_you_sure_to_clear_counters_onu: "Are you sure to clear counters?",
    onu_success_reseted: "ONT success reset",
    onu_success_counters_cleared: "Counters success cleared",
    action_clear_counters_onu: "Clear counters",
    action_reset_onu: "Reset ONT",
    admin_status: "Admin status",
    mac_address: "MAC-address",
    info_status: "Status information",
    last_down_reason: "Last down reason",
    online_since: "Online from",
    offline_since: "Offline from",
    distance: "Distance",
    check_modules_status: "Modules status",
    pon_fdb: "FDB table",
    onu_status: "Status ONT",
    pon_onts_general_info: "General info",
    pon_onts_mac_addr: "MAC ONT",
    vendor: "Vendor",
    hardware_ver: "Hardware ver.",
    software_ver: "Software ver.",
    optical_info: "Optical info",
    rx: "RX",
    tx: "TX",
    temp: "Temp",
    voltage: "Voltage",
    uni_port: "UNI port",
    meta_information: "Meta info",
    onts_info: "ONTs info",
    pon_onts_mac_addresses: "ONTs mac address",
    pon_onts_optical: "ONTs optical status",
    pon_onts_status_detailed: "ONTs status detailed",
    from_cache: "from cache",
    big_notify: {
        device_is_offline: "<h3>Device is offline!</h3><b>Displayed info from history</b>"
    },
    account_settings: "Account settings",
    logout: "Logout",
    extra_parameters: "Additional parameters",
    onts_not_found_by_search: "ONTs not found by search",
    server_errors: {
        BAD_REQUEST: "Bad request",
        SWC_CACHE_NOT_FOUND: "Device not respond and cache not found",
        SWC_ERROR_WORK_WITH_DEVICE: "Error works with device",
        GENERAL_SERVER_ERROR: "General error on server",
        SERVER_ERROR: "Server error",
        SWC_SNMP_EXCEPTION: "Error work with device over SNMP",
        INSUFFICIENT_PRIVILEGES: "Insufficient privileges",
        DEVICE_ALREADY_EXIST: "The device already exists",
    },
    device_success_deleted: "Device success deleted",
    search_placeholder: "Start type for search...",
    go_to_device: "Go to device",
    my_account: "My account",
    parameters_success_saved: "Parameters success saved",
    account_settings_saved: "Account settings saved",
    reload_from_cache: "Reload",
    device_success_registered: "Device success registered",
    cancel: "Cancel",
    service_profile: "Service profile",
    config_state: "Config state",
    vport_mode: "Vport mode",
    register: "Register",
    action_dereg_onu: "Dereg ONT",
    fdb_is_empty: "FDB table is empty",
    onu_success_registered: "ONT success registered",
    type: "Type",
    onu_registration: {
        choosed_template: "Choosed template",
        regular_expr: "Regular expression",
        input_regular_expression: "Write regular expression(PCRE) for input value",
        duplicate_key: "Duplicate key with name '{key}'",
        template_success_saved: "Template success saved",
        twig_easy_doc: `
            <h4>For compile template used Twig</h4>
            <span style="color: darkred; font-weight: bold">Read <a href="https://twig.symfony.com/doc/3.x/templates.html" target="_blank">official doc 'Twig for designers'</a>. <br></span><br>
        `,
        select_from_predefined_description: `
            Set list of parameters used new line 
        `,
        deprecated_unregistered_ont: "Unregistered ONT (deprecated)",
        unregistered_ont: "Unregistered ONT",
        key_reg_description: `
            Key must be match regex: ^[a-z][a-z_0-9]{1,}$.
            Key used for get parameter from variables, ex params.<key name>`,
        visible_if_description: `Condition on JavaScript for display`,
        select_from_predefined: "Choose from predefined",
        select_from_variable: "Choose from variables",
        save_parameters: "Save parameters",
        input_variable: "Field for input with default value",
        input_string: "Field for input",
        checkbox: "Checkbox",
        live_result: "Live result",
        add_parameter: "Add parameter",
        template: "Template",
        source_param_key: "Value source",
        default_value: "Default value",
        variants: "Variants",
        parameter_can_be_empty: "Parameter can be empty",
        key: "Key",
        visible_name: "Displayed name",
        parameter_type: "Parameter type",
        template_variables: "Config variables",
        predefined_variables_detailed: "Choose device for load variables",
        profile_line: "Line profile",
        profile_remote: "Remote profile",
        onu_number: "ONT number",
        input_string_description: `Allow add input fields. Setted value will be avaliable in params.<key>`,
        input_string_variable: ``,
        select_from_variable_description: ``,
        select_from_variables_list: "List of values for choose",
        variable_block: `
               <h4 style="color: darkred">For control parameters - choose device and ONT</h4>
              
         `,
        parameters_descriptions: `Allow add parameters. They will be avaliable in <b><i>params.&lt;param key&gt</i></b> `,
        parameters_success_saved: "Parameters success saved",
        are_you_sure_to_delete: "Are you sure to devete parameter?",
        element_success_deleted: "Parameter success deleted, save result",
        variables: "Variables",
        templates: "Templates",
        parameters: "Parameters",
        input_variable_description: "Allow set default value from variable",
        registration_form: "Registration form",
        template_block: "Template block",
        save_template: "Save template",
        template_compile_problem: "Error template compilation: ",
        onu_form_registration: "ONT registration form",
        is_required: "Required",

        visible_if: "Visible condition",
    },
    field_is_required: "Field is required",
    all: "All",
    lang_short: 'en',
    time_range: "Time range",
    actions: "Actions",
    action: "Action",
    loading: "Loading...",
    deprecated_unregistered_onts: "Unregistered ONTs (deprecated)",
    unregistered_onts: "Unregistered ONTs",
    data: "Data",
    time: "Time",
    message: "Message",
    filters: "Filters",
    detected_unregistered_onts: "Detected unregistered ONT",
    log_statuses: {
        'null': "Not finished",
        SUCCESS: "Success",
        FAILED: "FAILED",
    },
    sorry_no_matching_options: "Variants not found",
    select_all: "Select all",
    unselect_all: "Unselect all",
    user: "User",
    'module': "Module",
    modules: "Modules",
    arguments: "Arguments",
    switcher_core_logs: "SwitcherCore logs",
    log_device_calling: "Device calling logs",
    log_actions: "Actions",
    log_actions_full: "Actions logs",
    system_info: "System information",
    add_device: "Add device",
    serial: "Serial number",
    updated_at: "Updated at",
    access: "Access",
    interfaces: "Interface",
    back_to_interfaces: "Back to interfaces",
    reload_info: "Reload info",
    action_reboot_onu: "Reboot ONT",
    epon_onu_status: "EPON ONT status",
    gpon_onu_status: "GPON ONT status",
    fdb_table: "FDB table",
    logs: "Logs",
    no_name: "No name",
    signals: "Signal strength level",
    reg_time: "Registration time",
    auth_time: "Auth time",
    dereg_time: "Dereg time",
    reason: "Reason",
    technology: "Technology",
    interface: "Interface",
    type_configured: "Type configured",
    type_reported: "Type reported",
    ver_firmware: "Ver firmware",
    ether_info: "UNI ports",
    status_changed: "Count changes",
    line_profile: "Line-profile",
    admin_state: "Admin state",
    online_duration: "In online",
    model: "Model",
    device_group: "Device Group",
    uptime: "Uptime",
    found_onts: "Found ONTs",
    devices_not_found: "Devices not found",
    last_updated: "Updated",
    type_text_for_filter: "Start type for searching...",
    online: "Online",
    offline: "Offline",
    power_off: "Power off",
    search_device_placeholder: "Type name, IP or location...",
    dashboard: "Dashboard",
    devices: "Devices",
    device_management: "Device management",
    accesses: "Accesses",
    users_management: "Users management",
    users: "Users",
    groups: "Groups",
    user_roles: "Roles",
    config: "Configuration",
    devices_list: "Devices list",
    no_results: "No results",
    count_devices: "Count devices",
    errors_switcher_core_by_day: "Device calling errors<br><small>by day</small>",
    page_not_found: "PAGE NOT FOUND!",
    page_not_found_descr: "You can return to home!",
    back_to_home: "GO TO HOME",
    something_wrong: "Something wrong! Try reload page",
    try_reload_page: "Reload page",
    username: "Username",
    password: "Password",
    confirm_password: "Confirm Password",
    confirm_password_error: "Passwords do not match",
    password_instruction: "Leave blank if you don't want to replace old password",
    sign_in: "Sign in",
    login_or_password_incorrect: "Login or password is incorrect",
    unknown_error: "Unknown error",
    newer: "Newer",
    delete_account: "Delete account",
    dt_table: {
        count: "Displayed {from} to {to} from {count} records|{count} records|One record",
        first: 'First',
        last: 'Last',
        filter: "Filter:",
        filterPlaceholder: "Search",
        limit: "Limit:",
        page: "Page:",
        noResults: "Records not found",
        filterBy: "Filter by {column}",
        loading: 'Loading...',
        defaultOption: 'Choose {column}',
        columns: 'Columns'
    },
    personal_info: "Personal info",
    login: "Login",
    name: "Name",
    user_login: "Login",
    user_name: "Name",
    group: "Groups",
    status: "Status",
    active_sessions: "Active sessions",
    new_password: "New password",
    last_activity: "Last activity",
    save: 'Save',
    create: 'Create',
    user_list: 'Users list',
    edit_user: 'Edit user %{login}',
    user_successful_updated: "User %{name} success updated",
    remote_addr: "IP address",
    device: "Device",
    close_session: "Close session",
    session_closed: "Session %{id} success closed",
    are_you_sure_for_delete_user: "Are you sure to delete user?",
    are_you_sure_for_delete_group: "Are you sure to delete group?",
    are_you_sure_for_delete_device: "Are you sure to delete device?",
    group_successful_updated: "Group %{name} success updated",
    group_successful_created: "Group %{name} success created",
    group_success_deleted: "Group success deleted",
    delete_group: "Delete group",
    user_success_deleted: "User success deleted",
    user_successful_created: "User %{login} success created",
    create_new_user_btn: "Add user",
    create_new_usergroup_btn: "Add group",
    create_new_user: "Add new user",
    create_new_group: "Create group",
    group_permissions_count: "Count permissions",
    disabled: "Disabled",
    general_info: "General info",
    enabled: "Enabled",
    hidden: "Hidden",
    group_privileges: "Group privileges",
    edit_group: "Edit group %{name}",
    display: "Display",
    description: "Description",
    enter_name: "Enter name",
    enter_description: "Enter description",
    user_statuses: {
        enabled: 'Enabled',
        disabled: 'Disabled'
    },
    hide_success: "Hide success",
    contain_text: "Contain text",
    edit: "Edit",
    delete: "Delete",

    // Access page
    device_access_management: 'Access management',
    community: 'Community',
    create_new_access_btn: "Create new access",
    edit_access_with_id: "Edit access #%{id}",
    close: "Close",
    access_success_updated: "Access success updated",
    are_you_sure_for_delete_access: "Are you sure to delete access?",
    access_success_deleted: "Access success deleted",
    access_success_created: "Access success created",
    create_new_access: "Create new access",

    // Add device page
    create_new_device: "Add new device",
    mac: "MAC",
    ip: "IP",
    device_success_updated: "Device %{name} success updated",
    device_success_created: "Device %{name} success created",
    main: "Main",
    edit_device: "Edit device %{name}",
    device_id: "Device ID",
    created: "Created at",
    updated: "Updated at",
    type_more_symbols_for_search: "Type more 3 symbols for start searching...",
    device_view: "Device view",
    device_edit: "Device edit",

    // Macros
    macros: {
        item_filter: "Item filter",
        item_filter_description: "A scoped JavaScript expression that filter possible variants in condition. Condition must return boolean value. For example, if item {id: 0, name: 'defaultProfile'} and you want to hide it: item.id != 0",
        model_vendor: "Model vendors filter",
        item_name: "Item name",
        item_name_description: "A scoped JavaScript expression that sets the variable name for accessing objects in an array, i.e., ${item.name} to access [{item...}, {item_2...}]. 'item' is a keyword; the name can be custom.",
        execute_name: "Executing macro '{name}'",
        preview_commands: "Preview executed commands",
        executed_output: "Executed commands' results",
        execution: "Execution",
        show: "Macros",
        modal: "Macros",
        output: "Output",
        execution_success: "Successfully executed",
        edit_macros: "Edit Macros - \"%{name}\"",
        add_new: "Add New Macro",
        params_empty: "This macro has no parameters",
        incorrect_value_in_fields: "Some errors in fields",
        success_updated: "Macro updated successfully",
        success_created: "Macro created successfully",
        fail_updated: "Failed to update",
        fail_created: "Failed to create",
        confirm_remove: "Are you sure you want to remove this macro?",
        close: 'Close',
        preview: 'Preview',
        remove: 'Remove',
        cancel: 'Cancel',
        execute: 'Execute',
        config: 'Macros',
        list: 'Macros',
        add: 'Add new',
        import: 'Import',
        export: 'Export',
        edit: "Edit",
        interfaces: 'Interfaces',
        common: 'Common',
        form: 'Macros',
        create: 'Create',
        save: 'Save',
        display_for: 'Display for',
        display_for_options: {
            DEVICE: 'Device',
            PON: 'PON port',
            PORT: 'Port',
            ONU: 'ONU',
        },
        display_output: 'Display output',
        display_output_options: {
            all: "All commands",
            last: "Last command",
            no: "None"
        },
        name: 'Name',
        roles: 'Roles',
        user_roles: 'User roles',
        device: 'Device',
        models: 'Models',
        description: 'Description',
        regular_expr: "Regular expression",
        input_regular_expression: "You can specify a regular expression (PCRE) for the input field value. Optional.",
        duplicate_key: "Duplicate key with name '{key}'",
        template_success_saved: "Template saved successfully",
        twig_easy_doc: `
            <h4>Template compilation provided by Twig.</h4>
            <span style="color: darkred; font-weight: bold">Read <a href="https://twig.symfony.com/doc/3.x/templates.html" target="_blank">official docs 'Twig for designers'</a>. <br></span><br>
        `,
        select_from_predefined_description: `Specify the predefined values, one per line.`,
        key_reg_description: `Must match the regular expression: ^[a-z][a-z_0-9]{1,}$.`,
        visible_if_description: `JavaScript condition for property visibility in the macro execution pop-up. You can also reference other properties from this macro, i.e. params.property === "value".`,
        select_from_predefined: "Dropdown list from predefined",
        select_from_variable: "Dropdown list from variables",
        save_parameters: "Save parameters",
        input_variable: "Input field with value source",
        input_string: "Input field",
        checkbox: "Checkbox",
        live_result: "Live result",
        add_parameter: "Add parameter",
        template: "Template",
        source_param_key: "Value source",
        default_value: "Default value",
        variants: "Variants",
        parameter_can_be_empty: "Optional",
        key: "Property",
        visible_name: "Property display name",
        parameter_type: "Parameter type",
        template_variables: "Variable configuration",
        predefined_variables_detailed: "Choose a device to load variables",
        profile_line: "Line profile",
        profile_remote: "Remote profile",
        onu_number: "ONT number",
        input_string_description: ``,
        input_string_variable: ``,
        select_from_variable_description: ``,
        select_from_variables_list: "Predefined values list",
        variable_block: `<h4 style="color: darkred">Choose a Device and an Interface to which the parameters will be applied</h4>`,
        parameters_descriptions: `Add parameters to the macro below. They can be accessed through the dot notation as follows: {{ params.&lt;property&gt }}`,
        parameters_success_saved: "Parameters saved successfully",
        are_you_sure_to_delete: "Are you sure you want to delete this parameter?",
        element_success_deleted: "Parameter successfully deleted",
        variables: "Variables",
        templates: "Templates",
        parameters: "Parameters",
        input_variable_description: "You can set a default value for this property.",
        parameters_form: "Parameters form",
        template_block: "Template block",
        save_template: "Save template",
        template_compile_problem: "Template compilation error: ",
        is_required: "Required",
        visible_if: "Visibility condition",

    },

    password_too_short: "Password must be at least 8 characters long",
    password_no_uppercase: "Password must contain at least 1 capital letter",
    password_no_lowercase: "Password must contain at least 1 small letter",
    password_no_digit: "Password must contain at least 1 digit",
    password_no_special: "Password must contain at least 1 special character",
}
