export default {
    sys_config: {
        tabs: {
            system_info: "System information",
            system_configuration: "Configuration",
            components: "Components",
            schedule: "Schedule",
        },
        by_default: "by default: '{default}'",
        groups: {
            auto_topology: "Auto topology",
            trapservice: "SNMP traps",
            utels: "Component: Utels integration",
            notifications: "Notifications",
            analytics: "Analytics",
            web_panel: "Web panel",
            system: "System",
            security: "Security",
            proxy: "Proxy",
            switcher_core: "Working with devices",
            all_ok_billing: "Component: AllOkBilling",
            oxidized: "Oxidized (Configuration backups)",
            nodeny_plus: "NoDeny plus",
            search_device: "Search devices",
            mikbill: "MikBill",
            links: "Links",
        },
        parameter_name: {
            SECURE_CHECK_PASSWORD_STRENGTH: "Check password strength",
            ANALYTICS_MIN_RX_SIGNAL: "Min ONU RX signal",
            ANALYTICS_MAX_RX_SIGNAL: "Max ONU RX signal",
            ANALYTICS_MIN_OLT_RX_SIGNAL: "Min OLT RX signal",
            ANALYTICS_MAX_OLT_RX_SIGNAL: "Max OLT RX signal",
            LINKS_UTILIZATION_CALCULATE_PERIOD: "Utilization calculate period",
            LINKS_UTILIZATION_MAX_PRC_FOR_ALERT: "Percent of utilization for alert",

            AUTO_TOPOLOGY_SCHEDULE_ENABLED: "Auto running enabled",
            AUTO_TOPOLOGY_THREADS: "Count of threads",
            SEARCH2_FILTER: "Extra Search by",
            SEARCH2_ENABLED: "Extra Search status",
            TRAP_SERVICE_ENABLED: "SNMP traps enabled",
            TRAP_SERVICE_SNMP_PORT: "SNMP traps port",
            TRAP_SERVICE_COUNT_HANDLERS: "Count handlers",
            TRAP_SERVICE_IGNORE_UNKNOWN_TRAPS: "Ignore unknown traps",
            TRAP_SERVICE_CHECK_COMMUNITY: "Check trap community",
            NODENY_API_ADDR: "Nodeny HTTP address",
            NODENY_GLOBAL_SEARCH_FIELD: "Global search field",
            NODENY_SET_ONT_COORDINATES: "Set ONT coordinates",
            RATE_LIMITER_TIME: "Blocking by IP timeout",
            RATE_LIMITER_ATTEMPTS: "Incorrect login/password attempts",
            RATE_LIMITER_ENABLED: "Brute force protection",
            MIKBILL_SET_ONT_COORDINATES: "Detect ONU coordinates",
            ALL_OK_BILLING_SERVICE_URL: "Service URL",
            MAP_TILE_LAYER: "Map tile layer",
            SWC_ENABLE_SPLITTING: "Split response by interface",
            ANALYTICS_IGNORE_IFACES_WITH_MORE_THAN: "Ignore iface with more than",
            ANALYTICS_SHOW_ACTIVE_MORE_THAN: "Show ifaces with active more than",
            ALL_OK_BILLING_DATABASE_URL: "Database URL",
            ALL_OK_BILLING_DATABASE_USER: "Database username",
            ALL_OK_BILLING_DATABASE_PASSWD: "Database password",
            ALL_OK_BILLING_API_URL: "API url",
            MIKBILL_API_ADDR: "MikBill web address",
            MIKBILL_AUTH_KEY: "Auth key",
            MIKBILL_GLOBAL_SEARCH_FIELD: "Field for global search",
            WEB_ZOOM_MAIN: "Zoom web interface",
            WEB_ZOOM_IFRAME: "Zoom web interface (iframe)",
            SWC_CHECK_ICMP_PING: "Check ICMP status",
            NODENY_URL: "NoDeny web address",
            NODENY_DATABASE_DSN: "DSN URL for connect to database",
            NODENY_DATABASE_USERNAME: "Username for database",
            NODENY_DATABASE_PASSWORD: "Password for database",
            NODENY_COMPARISON_DESCRIPTION_REQUIRED: "The description must match the name",
            NODENY_COMPARISON_CHECK_TOPOLOGY: "Check topology in search",
            EXTERNAL_HTTP_ADDRESS: "External web address",
            OXIDIZED_URL: "Oxidized URL",
            OXIDIZED_THREADS: "Threads number",
            OXIDIZED_INTERVAL: "Backup interval",
            OXIDIZED_TIMEOUT: "Timeout",
            POLLER_DO_NOT_CLEAR_INTERFACES: "Do not clear interfaces",
            SWC_CONSOLE_WAIT_BYTE_SEC: "Wait stream data timeout",
            SWC_SNMP_VERSION: "SNMP version",
            NOTIFICATIONS_CHECK_PREVIOUS_MESSAGE: "Check previous notification",
            DEFAULT_LANGUAGE: 'Default language',
            APP_NAME: 'Application name',
            LOGS_RETURN_RESULTS_LIMIT: 'Limit returning logs',
            MAP_COORDINATES: 'Default map coordinates',
            PROMETHEUS_RETENTION_TIME: 'Prometheus retention time',
            LOG_LEVEL: 'Log level',
            RR_NUM_WORKERS: 'Count procs',
            POLLER_IGNORE_DOWN: 'Poller ignore DOWN hosts',
            POLLER_COUNT_PROCS: 'Poller count procs',
            API_KEY_EXPIRATION: 'API key expiration',
            TRUSTED_HOST_NETWORK_LIST: 'Trusted networks list',
            PROXY_ENABLED: 'Proxy enabled',
            PROXY_REAL_IP_HEADER: 'Real IP header',
            SWC_CONSOLE_CONN_TYPE: 'Connection type',
            SWC_CONSOLE_PORT: 'Console port',
            SWC_CONSOLE_TIMEOUT_SEC: 'Console timeout',
            SWC_SNMP_REPEATS: 'SNMP repeats',
            SWC_SNMP_TIMEOUT_SEC: 'SNMP timeout sec',
            SWC_SNMP_PORT: 'SNMP port',
            SWC_MIKROTIK_API_PORT: 'Mikrotik API port',
            SEARCH_DEVICE_SOURCES: 'Search sources',
            SEARCH_DEVICE_CHECK_ALL_SOURCES: 'Check all sources',
            SEARCH_DEVICE_HISTORY_ONLY_ACTIVE: 'Get only active',
            SEARCH_DEVICE_IGNORE_TAG_PORTS: 'Ignore tag ports',
            SEARCH_DEVICE_REQUEST_CONCURRENCY: 'Search concurrency',
            SWC_CACHE_ACTUALIZE_TIMEOUT_SEC: "Actualize timeout",
        },
        parameter_description: {
            SECURE_CHECK_PASSWORD_STRENGTH: "Check minimal password strength. Minimal 8 symbols with - uppercase, lowercase, number and special symbols",
            ANALYTICS_MIN_RX_SIGNAL: "Set minimal RX in dBm",
            ANALYTICS_MAX_RX_SIGNAL: "Set maximum RX in dBm",
            ANALYTICS_MIN_OLT_RX_SIGNAL: "Set minimal OLT RX in dBm",
            ANALYTICS_MAX_OLT_RX_SIGNAL: "Set maximum OLT RX in dBm",
            LINKS_UTILIZATION_CALCULATE_PERIOD: "A period in duration format (60s, 1m, 5m, 1h) for calculating link utilization",
            LINKS_UTILIZATION_MAX_PRC_FOR_ALERT: "Port utilization in percentage, above which an event will be triggered",

            AUTO_TOPOLOGY_SCHEDULE_ENABLED: "Run rebuild topology over schedule in background",
            AUTO_TOPOLOGY_THREADS: "Count of parallel processes. Must be less then RR_NUM_WORKERS (less 50%)",
            SEARCH2_FILTER: "Extra search by specified entity",
            SEARCH2_ENABLED: "Will be showed extra search in the header",
            TRAP_SERVICE_ENABLED: "",
            TRAP_SERVICE_SNMP_PORT: "Default - 162",
            TRAP_SERVICE_COUNT_HANDLERS: "Count workers (recommended x2 per core)",
            TRAP_SERVICE_IGNORE_UNKNOWN_TRAPS: "Default - disabled. When option is enabled, system will save trap without parsing(as raw data)",
            TRAP_SERVICE_CHECK_COMMUNITY: "When checking enabled, trap without correct community can't be saved",
            NODENY_API_ADDR: "Example - https://stat.domain.com",
            NODENY_GLOBAL_SEARCH_FIELD: "Field for search from billing",
            NODENY_SET_ONT_COORDINATES: "Try to find coordinates from billing address",
            RATE_LIMITER_TIME: "Time spent on the IP blacklist in seconds",
            RATE_LIMITER_ATTEMPTS: "Number of unsuccessful attempts to send an IP to the blacklist (per 10 minutes)",
            RATE_LIMITER_ENABLED: "Enable brute force protection with IP blocking",
            MIKBILL_SET_ONT_COORDINATES: "Try find ONU coordinates by billing address (using openstreetmap API)",
            ALL_OK_BILLING_SERVICE_URL: "For example - https://service.billing.com. Used for build billing links",
            MAP_TILE_LAYER: "Choose map source",
            SWC_ENABLE_SPLITTING: "When device called and returning data about many interfaces, response will be read by as unique interface. It's allow to cache some modules",
            ALL_OK_BILLING_DATABASE_URL: "",
            ALL_OK_BILLING_DATABASE_USER: "",
            ALL_OK_BILLING_DATABASE_PASSWD: "",
            ALL_OK_BILLING_API_URL: "",
            ANALYTICS_IGNORE_IFACES_WITH_MORE_THAN: "",
            ANALYTICS_SHOW_ACTIVE_MORE_THAN: "",
            MIKBILL_API_ADDR: "MikBill web address, as https://domain.corp",
            MIKBILL_AUTH_KEY: "Auth key for access to MikBill API (Its a key you setted in billing integration)",
            MIKBILL_GLOBAL_SEARCH_FIELD: "Field for global search and display in ports table",
            WEB_ZOOM_MAIN: "Default value - 1.0. !Experimental, having some bugs",
            WEB_ZOOM_IFRAME: "Default value - 1.0. !Experimental, having some bugs",
            SWC_CHECK_ICMP_PING: "Check device online status(from ICMP) before start calling SNMP/Telnet",
            EXTERNAL_HTTP_ADDRESS: "",
            NODENY_URL: "Web address to NoDeny, used for links to user cards. For example - https://billing.company.com",
            NODENY_DATABASE_DSN: "Used PDO URL format. For example - mysql:host=127.0.0.1;dbname=nodeny;charset=utf8",
            NODENY_DATABASE_USERNAME: "For example - root",
            NODENY_DATABASE_PASSWORD: "",
            NODENY_COMPARISON_DESCRIPTION_REQUIRED: "The description must match the name",
            NODENY_COMPARISON_CHECK_TOPOLOGY: "Check topology in search",
            OXIDIZED_TIMEOUT: "Timeout for working with device in seconds",
            OXIDIZED_URL: "Url to oxidize instance. Do not change it, if you use build in",
            OXIDIZED_THREADS: "Max threads for working with devices. Recommended - threads = count CPU cores",
            OXIDIZED_INTERVAL: "Interval to backup in seconds",
            SWC_CACHE_ACTUALIZE_TIMEOUT_SEC: "Actualize timeout in seconds. When cache is expired, cache update from device",
            POLLER_DO_NOT_CLEAR_INTERFACES: "Not clear interfaces automatically. If option enabled - poller not remove old interfaces",
            SWC_CONSOLE_WAIT_BYTE_SEC: "Max seconds for waiting bit of data, when command executed",
            SWC_SNMP_VERSION: "",
            NOTIFICATIONS_CHECK_PREVIOUS_MESSAGE: "When event resolved, system can check previous notifications and ignore sending resolved message",
            DEFAULT_LANGUAGE: 'Default language for new users',
            APP_NAME: '',
            LOGS_RETURN_RESULTS_LIMIT: 'Max lines returned in logs',
            MAP_COORDINATES: 'Default coordinates for map. Default - Kiev, Ukraine',
            PROMETHEUS_RETENTION_TIME: 'Retention time for prometheus history',
            LOG_LEVEL: '',
            RR_NUM_WORKERS: 'Count of threads for process API requests',
            POLLER_IGNORE_DOWN: 'Do not poll the device if it does not respond via ICMP',
            POLLER_COUNT_PROCS: 'Number of background pollers (how many devices to poll at the same time)',
            API_KEY_EXPIRATION: 'Api key expiration in seconds. Default - 864000 (10 days)',
            TRUSTED_HOST_NETWORK_LIST: 'Network list presented as CIDR of trusted networks. API calling in trusted networks allow send request without header X-Auth-Key(with setting default user System)',
            PROXY_ENABLED: 'If wildcore proxied over proxy, enable this flag',
            PROXY_REAL_IP_HEADER: 'Header name with real IP of user',
            SWC_CONSOLE_CONN_TYPE: 'Type of device console connection',
            SWC_CONSOLE_PORT: '',
            SWC_CONSOLE_TIMEOUT_SEC: 'Max session time in seconds',
            SWC_SNMP_REPEATS: '',
            SWC_SNMP_TIMEOUT_SEC: '',
            SWC_SNMP_PORT: '',
            SWC_MIKROTIK_API_PORT: '',
            SEARCH_DEVICE_SOURCES: '',
            SEARCH_DEVICE_CHECK_ALL_SOURCES: '',
            SEARCH_DEVICE_HISTORY_ONLY_ACTIVE: '',
            SEARCH_DEVICE_IGNORE_TAG_PORTS: '',
            SEARCH_DEVICE_REQUEST_CONCURRENCY: '',
        },
    }
}