export default {
    utilization_info_not_avaliable: "Нет инфо о нагрузке",
    time_ago: "Время назад",
    upward_topology: " Восходящая топология ",
    link_list: "Список соединений",
    links_with_highload: "Кол. нагруженных линков",
    only_high_utilization: "Только нагруженные",
    topology: "Топология",
    maps: "Карты",
    onts_with_bad_signals: "ОНУшек с плохим сигналом",
    only_bad_signals: "Только плохие сигналы",
    "is_internal_rule": `
     Это внутреннее правило  <br>
     <small>Некоторые параметры недоступны для редактирования, они контролируются системой</small>
    `,
    "src_iface": "Исходный интерфейс",
    "dest_iface": "Целевой интерфейс",
    "uplinks": "Входящие каналы",
    "downlinks": "Исходящие каналы",
    "positions_success_saved": "Позиции успешно сохранены",
    "auto_reload": "Автоперезагрузка",
    "open_parameters": "Открыть параметры",
    "enable_fullscreen": "Включить полноэкранный режим",
    "unknown_interface": "Неизвестный интерфейс",
    "utilization": "Загрузка",
    "link_speed": "Скорость канала",
    "configure_your_topology": "Настройте визуализацию топологии",
    "minimal_link_utilization": "Показать каналы с загрузкой больше %",
    "enable_physics": "Включить физику",
    "save_object_positions": "Сохранить позиции объектов",
    "save_position": "Сохранить позицию",
    "hide_devices_without_links": "Скрыть устройства без каналов",
    "hide_level_above": "Скрыть устройства выше уровня",
    "util_time_period": "Временной период",
    "has_errors": "Имеет ошибки",
    "has_modules": "Имеет модули",
    "traps": {
        "name": "SNMP Трапы",
        "export": "Экспорт трапа"
    },
    no_links: "Нету линков",
    no_links_small_info: "Аплинков нету. Что бы добавить аплинк - перейдите к редактированию устройства",
    adjacent_interfaces: "Смежные интерфейсы",
    not_found_adjacent_interfaces: "Смежные интерфейсы не найдены",
    any: "Любое",
    active: "Активен",
    not_active: "Не активен",
    signal_type: "Тип сигнала",
    no_data_to_show: "Нет данных для отображения",
    iface_history_table: "Таблица аптайма",
    uptime_time: "Время аптайма",
    days_short: "дн.",
    no_interface: "Без интерфейса",
    start_write_for_filter: "Начните вводить для фильтра...",
    object_filter: "Фильтр обьектов",
    log_traps: "SNMP-traps",
    srv_profile: "Сервис-профайл",
    export_excel: "Скачать в excel",
    "google_twofa_pin_instruction": "Пожалуйста, введите шестизначный код, полученный в приложении для авторизации",
    "choose_device": "Выберите устройство",
    "choose_interface": "Выберите интерфейс",
    info: "Инфо",
    select: "Выбрать",
    onts_registration: {
        preview: "Просмотр команд для выполнения",
        exec: "Результат регистрации",
        onu_success_registered_searching: "ONT зарегистрирована! \nПоиск...",
        onu_success_registered_and_found: "ONT успешно зарегистрирована и найдена!",
        registered_but_not_found: "ONT зарегистрирована, но не найдена автоматически. Пожалуйста, проверьте журналы или найдите вручную",
        reload_variables_from_device: "Перезагрузить данные с устройства",
        registration_template_not_configured_for_model: "<span style='color: darkred'>Не найдено шаблона регистрации для данной модели, регистрация невозможна. <br>Пожалуйста, создайте шаблон</span>",
        please_choose_unregistered_ont: "Пожалуйста, выберите незарегистрированную ОНУ",
        config: "Регистрация ОНУ",
        list: "Список макросов для регистрации ОНУ",
        import: "Импорт макроса",
        add: "Добавить макрос",
        edit: "Редактировать макрос",
        remove: "Удалить макрос",
        export: "Экспорт макроса (json)",
        edit_macros: "Редактирование макросов '{name}'",
        model_vendor: "Производитель устройства",
        models: "Модели устройств",
        device: "Устройство",
        confirm_remove: "Вы уверены, что хотите удалить шаблон?",
        success_deleted: "Успешно удалено",
        success_created: "Успешно создано",
        fail_updated: "Не удалось обновить макросы",
        success_updated: "Успешно обновлено",
        add_new: "Добавить новый макрос",
        unregisteredOnts: "Незарегистрированные ОНУ",
        error_read_imported_macros: "Ошибка загрузки импортированных макросов",
    },
    pinger_info: "Информация пингера",
    increasing_errors_by_day: "Рост ошибок за последние 24 часа",
    step: "шаг",
    increasing_errors: "Ошибки на интерфейсах",
    chart: "График",
    increasing_errors_for_iface: "График роста ошибок на интерфейсе {iface}",
    counter_in_errors: "Вх. ошибки",
    counter_out_errors: "Исх. ошибки",
    increasing_in_errors: "Рост вх. ошибок",
    increasing_out_errors: "Рост исх. ошибок",
    copy: "Копировать",
    copied: "Скопировано",
    twofa_confirm: "Вы действительно хотите отключить 2FA?",
    twofa: "2FA",
    twofa_please_enter_pin: "Пожалуйста, введите пин-код",
    twofa_instruction: "Отсканируйте QR-код с помощью <a href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ru&gl=US&pli=1'>Google Authenticator</a>, и введите полученный код",
    twofa_disconnect: "Отключить",
    twofa_manual: "Или введите код вручную ",
    twofa_connect: "Включить",
    twofa_connect_submit: "Подтвердить",
    wrong_pin: "Пин-код неверный",
    twofa_connected: "2FA включена",
    connect_2fa: "Отсканируйте QR код через Google Authenticator",
    twofa_pin: "ПИН код",
    twofa_enabled: "2FA включен",
    n_a: "Н/Д",
    macros_not_found: "Макросы не найдены",
    hide_online: "Спрятать онлайн",
    show_only_favorite: "Только избранные",
    save_fdb_history: "Сохранять FDB в историю",
    duration: "Продолжительность",
    availability: "Доступность",
    periods: {
        '24h': "1 день",
        '1d': "1 день",
        '3d': "3 дня",
        '7d': "7 дней",
        '30d': "30 дней",
    },
    down_logs: "Логи падений",
    packet_loss: "Потери пакетов",
    online_from: "Онлайн с ",
    offline_from: "Офлайн с ",
    durations: {
        year: 'г',
        month: 'мес',
        week: 'нд',
        day: 'д',
        hour: 'ч',
        minute: 'м',
        seconds: 'с',
        millis: 'мс',
    },
    no_pollers: "Нет опросчиков",
    lacp_interfaces: "Интерфейсы в LACP",
    clone: "Клонировать",
    uni_interfaces_vlans: "Вланы на UNI",
    ont_registered_but_not_found_automaticaly: "ОНУ зарегистрирована, но не удалось найти ее по серийному номеру. Попробуйте перейти на ОНУ вручную, предварительно обновив вкладку",
    onts_list: "Список ОНУ",
    ont_statistic: "Статистика ОНУ",
    traffic_chart: "Трафик (график)",
    traffic_live_view: "Трафик (график онлайн)",
    ont_success_reset: "ОНУ успешно сброшена!",
    are_you_sure_to_reset_ont: "Уверены, что хотите сбросить ОНУ?",
    duplicated_onts: "Дубликаты ОНУ",
    device_is_offline_big_message: `
    <h4>Устройство оффлайн (не отвечает по SNMP/консоли)</h3>Некоторая информация может быть отображена с истории
    `,
    please_wait_working_with_device: "Пожалуйста, подождите, запрашиваются данные от оборудования...",
    filters_modal: "Фильтры",
    show_devices: "Показать устройства",
    show_onts: "Показать онты",
    show_links: "Показать ссылки",
    clustering: "Группировка",
    source: "Источник",
    destination: "Назначение",
    ont_level_strength: "Уровни сигнала ОНУ",
    duplicated_mac: "Задублированный MAC",
    duplicated_macs: "Задублированные MAC-и",
    count_duplicates: "Кол. дублей",
    duplicates: "Дубликаты",
    first: "Первый",
    last: "Последний",
    ifaces_detailed_statistic: "Детальная инфо о интерфейсах",
    interfaces_stat_type: "Отображать статистику",
    splitted: "По типам",
    rules_not_found: "Правила не найдены",
    uni_port_state_success_changed: "UNI-порт успешно изменен",
    activated_box_license: "Коробочная лицензия",
    abonent: "Абонент",
    information_not_collected_yet: "Информация пока недоступна, попробуйте позже",
    open_in_billing: "Открыть в биллинге",
    uid: "UID",
    toggle_not_avaliable_becouse_input: "Переключение невозможно, так как порт находится в состоянии приема",
    analog_lines_list: "Аналоговые линии",
    digital_lines_list: "Цифровые линии",
    power_control_output_list: "Управление питанием",
    power_sensor_state: "Датчик питания",
    sensors_not_found: "Датчики не найдены",
    knock_sensor_state: "Датчик удара",
    digital_direction_input: "Входящий",
    digital_direction_output: "Исходящий",
    low: "Низкий",
    high: "Высокий",
    power_supply: "Сеть 220В",
    security: "Безопасность",
    water_leak: "Детектор протечки",
    smoke_detector: "Детектор дыма",
    direction: "Направление",
    controlled_by_pings: "Контролируется пингом",
    controlled_by_analog_line: "Контролируется аналоговым входом",
    seems_sensor_not_supported: "Похоже, тип датчиков не поддерживается",
    temperature: "Температура",
    mark_as: "Маркер",
    not_marked: "Нет маркера",
    no_related_information_to_show: "Нет информации для отображения",
    edit_port_state: "Изменить порт",
    are_you_sure_to_reset_port: "Данное действие может привести к отключению всех ONU на интерфейсе. \nУверены, что хотите сбросить порт?",
    port_success_reset: "Порт успешно сброшен!",
    reset_port: "Сбросить порт (выкл-вкл + ресет)",
    card_list: "Список карт",
    card_status: "Статусы карт",
    oper_status: "Актуальное состояние",
    physical_ports_not_found: "Физические порты не обнаружены",
    big_noty_subscription_limit_reached: `
        <h3>Достигнут лимит интерфейсов по подписке!</h3>
        Некоторый функционал, такой как: добаление новых устройств, новых интерфейсов недоступен 
    `,
    big_noty_connect_to_validation_server_failed: `
        <h3>Нет подключения к серверу валидации!</h3>
        Пожалуйста, проверьте доступ к интернет и доступ к api.wildcore.tools на сервере с wildcore 
    `,
    favorite_interfaces: "Избранные интерфейсы",
    tagged_interfaces: "Интерфейсы с тегами",
    favorite_list: "Список избранных",
    error: "ОШИБКА",
    interface_mark: "Отметки интерфейса",
    tags: "Теги",
    local_id: "Локальный ID",
    write_list_ip_address_with_cidr_format: "Пропишите список разрешенных IP/сетей в формате CIDR",
    strict_by_ip_access: "Ограничение входа по IP",
    enable_strict_ip: "Включить ограничение по IP",
    allowed_ip_placeholder: "192.168.0.0/24",
    billing_info: "Инфо с биллинга",
    contract: 'Контракт',
    on: 'Включен',
    off: 'Выключен',
    device_not_found_in_oxidized: "Device not found in oxidized",
    last_config: "Последняя конфигурация",
    oxidized_configuration: "Oxidized конфигурация",
    last_status: "Статус",
    start: "Старт",
    end: "Конец",
    spent: "Заняло",
    open_oxidized: "Открыть в oxidized",
    oxidized: "Oxidized",
    backup_oxidized_enabled: "Включить бекап с Oxidized",
    agent_key: "Ключ агента",
    registered_ip: "Зарегистрированный IP",
    reserved_interfaces: "Зарезервировано интерфейсов",
    from: "С",
    allowed_components: "Разрешенные компоненты",
    version: "Версия",
    count_groups: "Количество групп",
    usage_statistic: "Статистика использования",
    subscription_info: "Информация о подписке",
    preview_commands_before_send: "Показать команды перед отправкой",
    interfaces_not_loaded: "Интерфейсы еще не загружены...",
    are_you_sure_for_clear_poller_history: "Уверены, что хотите очистить историю?",
    success_cleared: "Успешно очищено",
    clear_history: "Очистить историю",
    ont_statuses: "ОНТ статус",
    device_statuses: "Статусы устройств",
    analytics_click_on_chart_for_load_data: "Кликните на графике, на необходимое время, для загрузки таблицы статусов",
    graph_step: "Шаг графика",
    chart_data: "График",
    analytics: "Аналитика",
    rebuild_required_description: `
      <span style="font-weight: bold; color: darkred">*</span>  Для применения этих параметров, необходимо перезагрузить систему командой<pre>cd /opt/wildcore-dms && sudo docker compose up -d --build</pre>
    `,
    are_you_sure_to_reboot_onu: "Ви впевнені що хочете перезавантажити ОНУ?",
    are_you_sure_to_delete_ont: "Ви впевнені що хочете видалити ОНУ?",
    ont_success_deleted: "ОНУ успішно видалено",
    breadcrumb_show: "Показать устройство",
    has_active_pollers: "В данный момент выполняется фоновый сборщик. Информация может загружаться на много дольше",
    history_is_empty: "История падений отсутствует",
    public_community: "Public community",
    private_community: "Private community",
    public_community_placeholder: "public",
    private_community_placeholder: "private",
    snmp_version: "Версия SNMP",
    interfaces_list: "Список устройств",
    current_channel: "Текущий канал",
    auth_mode: "Auth mode",
    sn_bind: "SN bind",
    dba_mode: "DBA mode",
    fec: "FEC",
    fec_actual_mode: "FEC actual mode",
    pps1_tod: "PPS1 TOD",
    auto_replace: "Auto replace",
    mcast_encrypt: "Mcast encrypt",
    down_history_table: "История падений пустая",
    port_loading: "Загрузка порта",
    poller_in_process: "Фоновый сборщик работает",
    reboot_device: "Перезагрузка устройства",
    save_config: "Сохранить конфигурацию",
    field_name: "Имя поля",
    ident: "Идентификатор",
    optical_rx: "Сигнал RX",
    optical_tx: "Сигнал TX",
    optical_olt_rx: "Сигнал OLT TX",
    optical_temperature: "Температура",
    optical_distance: "Дистанция",
    no_filter: "Нет фильтра",
    poller_data: "Данные сборщиков",
    ont_list: "Список ОНУ",
    agreement: "Номер договора",
    value: "Значение",
    filter_field_name: "Поле фильтра",
    no_data: "Нет данных",
    disable_ont: "Отключить ОНУ",
    lease_info: "Лизы",
    go_to_boxes_list: "Перейти к списку боксов",
    pon_ports_list: "Список ПОН-портов",
    pon_ports_optical: "Уровни сигналов ПОН-портов",
    sys_resources: "Системные ресурсы",
    incorrect_value_for: "Некорректное значение для '{value}'",
    supported_modules: "Поддерживаемые модули",
    interface_not_loaded: "Интерфейсы не загружены",
    reboot_ont: "Перезагрузить ОНУ",
    address_list_info: "Адрес-лист",
    arp_info: "ARPs",
    dhcp_server_info: "DHCP-сервера",
    leases_info: "Лизы",
    simple_queue_info: "Шейпер",
    device_is_offline: "Устройство оффлайн",
    device_dash: {
        tabs: {
            history_log: "Лог статусов",
            macros: 'Макросы',
            pinger: 'Пингер',
            onts_tree: "Дерево ОНУ",
            events: "События",
            pon_boxes: "PON-боксы",
            deprecated_unregistered_onts: "Незарегистрированные ОНУ (устаревшее)",
            unregistered_onts: "Незарегистрированные ОНУ",
            ports: "Порты",
            interfaces: "Интерфейсы",
            address_list: "Адрес-листы",
            arps: "АРПы",
            leases: "Лизы",
            dhcp_servers: "DHCP-сервера",
            simple_queues: "Шейпер",
            bgp_sessions: "BGP-сессии",
            topology: "Топология",
            vlans: "Вланы",
            oxidized: "Бекапы",
            card_interfaces: "Физ интерфейсы",
        }
    },
    uni_extra_info: {
        type: "Тип",
        flow_control_status: "Статус управления потоком",
        pvid_mode: "Режим PVID",
        admin_status: "Admin status",
        vlan_id: "Vlan ID",
        vlan_mode: "Режим Vlan",
        admin_state: "Admin state",
        duplex: "Дуплекс",
        speed: "Скорость",
        id: "ID",
        name: "Имя",
        mode: "Режим",
        pvid: "PVID",
        trunk_vlans: "Trunk vlans",
        uni_interfaces_vlans: "UNI interfaces vlans",
        auto_negotation: "Auto negotation",
        flow_control: "Flow control",
        vlan_pvid: "PVID",
        vlan: "VLAN",
    },
    gpon_profiles: "GPON-профайлы",
    auth_info: "Auth info",
    ont_configuration: "Конфигурация ОНУ",
    last_dereg: "Последнее дерегистрация",
    last_dereg_since: "Последнее дерегистрация с момента",
    last_reg: "Последняя регистрация",
    parse_interface: "Прочитать интерфейс",
    olt: "ОЛТ",
    ont_status: "Статус ОНУ",
    storage_info: "Данные хранилища",
    vendor_info: "Информация о вендоре",
    poll_enabled: "Сбор включен",
    ver_hardware: "Ver hardware",
    ver_software: "Ver software",
    extra_info: "Доп информация",
    go_to_events: "Перейти к событиям",
    go_to_devices_list: "Перейти к списку устройств",
    from_storage: "С хранилища",
    from_device: "С устройства",
    device_calling: "Вызовы устроства",
    has_configuration: "Есть конфигурация",
    view: "Просмотр",
    component_configuration: "Конфигурация компонентов",
    load_config: "Загрузка конфигурации",
    loading_is_very_slow: `Загрузка очень медленная :-( <br><small>Попробуйте перезагрузить страницу</small>`,
    reload_page: "Перезагрузить страницу",
    online_devices: "Онлайн устройства",
    up_interfaces_chart: "График интерфейсов",
    ont_statuses_pie: "Статус ОНУ",
    widgets: {
        tags: "Теги",
        favorite_interfaces: "Избранные интерфейсы",
        high_links_utilization: "Кол. линков с высокой нагрузкой",
        bad_ont_signal: "Кол. ОНУ с плохим сигналом",
        ont_statuses_pie: "Статусы ОНУ",
        ont_signal_bar: "Уровни сигналов ОНУ",
        pinger_stat: "Статистика пингера",
        events_stat: "Статистика событий (по уровням)",
        events_stat_by_name: "Статистика событий (по имени)",
        last_user_activity: "Последняя активность",
        events_table: "Таблица событий",
        unregistered_onts: "Таблица незарегистрированных ОНУ",
        unregistered_onts_table: "Таблица незарегистрированных ОНУ (устаревшее)",
        system_stat: "Системная статистика",
        device_calling_errors_stat: "Ошибки вызова оборудования",
        device_status_chart: "График статусов устройств",
        online_onts_chart: "График онлайн ОНУ",
        up_ifaces_chart: "График онлайн интерфейсов",
    },
    delete_access: "Удалить доступ",
    edit_access: "Изменить доступ",
    distantion: "Дистанция",
    dashboard_saved: "Дашборд сохранен",
    events_by_name_stat: "Кол. событий по имени (посл. сутки)",
    count: "Количество",
    device_calling_errors_stat: "Ошибки работы с оборудованием (посл. сутки)",
    errors_count: "Кол. ошибок",
    not_responding_count: "Кол. без ответа",
    top_devices_by_errors: "Топ устройств по ошибкам",
    system_stat: "Сводная хранилища",
    count_device_groups: "Кол. групп",
    count_users: "Кол. пользователей",
    count_roles: "Кол. ролей",
    unregistered_onts_not_found: "Незарегистрированные ОНУ не найдены",
    set_for_all_users: "Установить для всех",
    role_successful_updated: "Роль успешно обновлена",
    user_role: "Пользовательская роль",
    add_widget: "Добавить виджет",
    choose_widget: "Выберите виджет",
    pinger: "Пингер",
    last_user_activity: "Посл. активность",
    events_table: "Таблица собыий",
    events_stat: "Сводная событий",
    onu_offline_signals_not_avaliable: "ОНУ офлайн, просмотр сигналов в реалтайм невозможно",
    live_traffic_view: "Реалтайм трафик",
    update_interval: "Интервал обновлений",
    in: "In",
    out: "Out",
    current: "Текущее",
    avg: "Среднее",
    max: "Максимальное",
    enable_ont: "Включить ОНУ",
    last_change: "Посл. изменение",
    omcc_version: "OMCC версия",
    model_id: "Model ID",
    versions: "Версии ПО",
    vlan_profile_name: "VLAN profile name",
    mode: "Mode",
    trunk_vlans: "Trunk vlans",
    description_success_updated: "Описание успешно обновлено",
    ont_success_enable: "ОНУ успешно включено",
    ont_success_disable: "ОНУ успешно выключено",
    are_you_sure_to_disable_ont: "Уверены, что хотите выключить ОНУ?",
    are_you_sure_to_enable_ont: "Уверены, что хотите включить ОНУ?",
    check_uplink: "Проверять аплинк",
    link_types: {
        manual: "Manual",
        lldp: 'LLDP',
        fdb: 'FDB',
    },
    coordinates_not_setted: "Координаты не установлены",
    interfaces_list_success_polled: "Опрос списка интерфейсов выполнен успешно",
    not_setted: "Не установлено",
    not_choosed: "Не выбрано",
    choose_device_is_required: "Выбор устройства обязателен",
    delete_link: "Удалить линк",
    are_you_sure_delete_link: "Уверены, что хотите удалить линк?",
    links_not_added: "Линки не добавлены",
    link_connection_type: "Тип линка для текущего устройства",
    uplink: "Uplink",
    downlink: "Downlink",
    uplink_device: "Uplink-устройство",
    uplink_interface: "Uplink-интерфейс",
    downlink_device: "Downlink-устройство",
    downlink_interface: "Downlink-интерфейс",
    snmp_port: "SNMP порт",
    choose_device_coordinates_before_start_work_with_device: "Для работы с данным устройством - сначало установите координаты",

    phpmyadmin: "phpMyAdmin",
    creating_new_link: "Create new link",
    searching_string: "Фильтр устройства",
    choose_devices_for_send_notifications: "Выберите устройства для отправки уведомлений",
    edit_monitoring_devices: "Изменить устройства для мониторинга",
    edit_notification_devices: "Изменить устройства для мониторинга",
    address_not_found: "Адрес не найден",
    click_to_button_map_for_set_coordinates: "Нажмите на кнопку справа, что бы установить координаты",
    choose_coordinate: "Указать координаты",
    display_by_status: "Подсвечивать по статусу",
    by_status: "Статус",
    no_mon_interfaces: "Не мониторится",
    count_mon_interfaces: "Кол. ОНУ мониторинга",
    location_from_coordinates: "Месторасположение по координатам",
    add_new_box: "Добавить новый бокс",
    create_new_box: "Добавление бокса",
    template_info: "О шаблоне",
    template_id: "ID шаблона",
    count_boxes: "Количество боксов",
    count_links: "Количество линков",
    template: "Шаблон",
    choose_onts_for_monitoring: "Выберите ОНУ для мониторинга",
    mon_onts: "Мониторинг через ОНУ",
    to_boxes: "К списку боксов",
    view_on_map: "Открыть шаблон на карте",
    box_id: "ID бокса",
    box_info: "Информация о боксе",
    links: "Соединения",
    success_saved: "Успешно сохранено!",
    are_you_sure_to_delete_box: "Уверены, что хотите удалить бокс?",
    are_you_sure_delete_element: "Уверены, что хотите удалить элемент?",
    are_you_sure_to_delete_link: "Уверены, что хотите удалить линк?",
    edit_box_with_name: "Изменить бокс с именем {name}",
    coordinates_note_setted: "Координаты не установлены",
    neighbor: "Сосед",
    editing_link_with_id: "Изменение линка с ID={id}",
    coordinates: "Местоположение",
    type_or_name_not_setted_object_deleted: "Тип или имя обьекта не указано, создание будет отменено",
    mon_interfaces: "Кол. ОНУ мониторинга",
    add_link: "Добавить линк",
    pon_boxes: {
        name: 'PON боксы',
        list: 'Список боксов',
        map: 'Карта',
        types: 'Настроить типы',
        map_builder: "Редактор карты",
        map_edit: "Изменение карты",
        tabs: {
            boxes: 'Типы боксов',
            links: 'Типы линков',
        },
    },
    edit_type: 'Изменить тип',
    general: 'Основное',
    styling: 'Стилизация',
    size: 'Размер',
    color: 'Цвет',
    stroke_size: 'Размер ободка',
    stroke_color: 'Цвет ободка',
    box_types: {
        tabs: {
            boxes: 'Боксы',
            links: 'Линки',
        }
    },
    add_element: 'Добавить элемент',
    map: 'Карта',
    edit_box: 'Изменить бокс',
    box_name: 'Имя бокса',
    monitoring_onts: 'ONTs для мониторинга',
    control: 'Управление',
    edit_link: 'Изменить линк',
    element_success_created: 'Элемент успешно создан',
    for_control_choose_device_and_interface: 'Выберите устройство и интерфейс для мониторинга через ONTs',
    error_choose_device_must_contain_coordinates: 'Ошибка выбора устройства. Устроство должно содержать координаты',
    poller_not_worked_yet: "Поллер еще не работал",
    start_poller_requested: "Запуск поллера запрошен",
    error_loading_info: "Ошибка получения информации",
    global: "Глобальные",
    notification_place: "Размещение уведомлений",
    noty_places: {
        topLeft: "Сверху слева",
        topRight: "Сверху справа",
        topCenter: "Сверху по центру",
        center: "По центру",
        centerLeft: "По центру слева",
        centerRight: "По центру справа",
        bottom: "Внизу",
        bottomLeft: "Внизу слева",
        bottomCenter: "Внизу по центру",
        bottomRight: "Внизу справа",
    },
    vlan_pvid: "VLAN Pvid",
    vlan_mode: "VLAN Mode",
    auto_negotation: "Auto negotation",
    flow_control: "Flow control",
    dhcp_snooping: "DHCP Snooping",

    show_groups: "Группировать",
    old_agent_version_reload_your_page: "<h3>Вы используете старую веб-панели. Пожалуйста, перезагрузите страницу без кеша (CTRL+F5)</h3>",
    agent_is_disabled: "Агент выключен. Пожалуйста, свяжитесь с support@wildcore.tools",
    incorrect_field_must_be_regex: "Некорректный ввод. Должен соответствовать '{regexp}'",
    last_reg_since: "Зарегистрирован с ",
    detailed_info: "Атрибуты",
    ignore_actions: "Игнорировать действия",
    rule_success_validated: "Правило успешно проверено",
    show_pinger_info: "Показать инфо с пингера",
    delete_role: "Удалить роль",
    start_write_for_search: "Searching...",
    start_write_for_search_by: "Searching by...",
    validate_rule: "Валидировать роль",
    add_action_for_notification: "Добавить нотификацию для действия",
    portal_settings: "Настройки портала",
    show_error_counters: "Показать ошибки счечиков",
    show_events: "Показать события",
    show_pinger_inf: "Показывать инфу с пингера",
    show_unregistered_onts: "Показать незарегистрированные ОНУ",
    expand_pon_tree: "Раскрыть дерево PON",
    device_dashboard: "Список устройств",
    down_devices_on_top: "Упавшие устройства в начале",
    sort_by: "Сортировать по",
    your_role: "Ваша роль",
    notifications_configuration: "Настройка уведомлений",
    log_poller: "Логи опросчика",
    pollers: "Опросчики",
    poller: "Опросчик",
    default_pollers: "Опросчики по-умолчанию",
    poller_configuration: "Настройки опросчика",
    use_default_poll_configuration: "Использовать настройки опросчика по-умолчанию",
    poller_name: "Имя опросчика",
    interval: "Интервал (сек)",
    configure_port_polling: "Настройка опросчиков по портам",
    count_interfaces: "Количество интерфейсов",
    count_polled: "Количество опрашиваемых интерфейсов",
    edit_ports: "Изменить порты",
    bind_key: "Bind ID",
    enable_polling: "Включить опрос",
    edit_interface_polling: "Изменить опрос интерфейса",
    interfaces_list_not_loaded: "Список интерфейсов не загружен",
    poll_interfaces: "Опросить интерфейсы",
    run_poller: "Запустить опросчик",
    roles: "Роли",
    user_role_list: "Пользотельские роли",
    create_new_role_btn: "Создать новую роль",
    role_permissions_count: "Количество прав в роли",
    edit_role: "Изменить роль",
    role_privileges: "Привилегии роли",
    create_new_role: "Создать новую роль",
    role_successful_created: "Роль успешно создана",
    are_you_sure_for_delete_role: "Уверены, что хотите удалить роль?",
    role_success_deleted: "Роль успешно удалена",
    role: "Роль",
    configure_notifications_by_events: "Настроить уведомления по событиям",
    configure_notifications_by_actions: "Настроить уведомления по действия",
    add_event_for_notification: "Добавить событие для уведомлений",
    event_name: "Имя события",
    send_resolved: "Отправлять решенные",
    delay_before_send: "Ожидание перед отправкой",
    action_name: "Имя действия",
    send_on_status_failed: "Отправлять при FAILED",
    send_on_status_success: "Отправлять при SUCCESS",
    action_notification: "Уведомление",
    event_configuration: "Настройка событий",
    group_name: "Имя группы",
    for: "Для",
    alert_name: "Имя алерта",
    expression: "Выражение",
    annotation_summary: "Аннотация",
    annotation_description: "Описание",
    notification_configuration_success_saved: "Настройка конфигурации успешно сохранена!",
    add_rule: "Добавить правило",
    events: {
        name: "События",
        tabs: {
            alertmanager: "Alertmanager",
        }
    },
    rules_success_saved: "Правила успешно сохранены",
    notifications: {
        contact_telegram_message: `
        <div style="margin-top: 10px">
        Вы можете узнать telegram chat id через бот - <a href="https://t.me/myidbot" target="_blank">@IDbot</a>. Бот вернет ваш id чата, введите его здесь<br>
        <div style="text-align: center; font-weight: bold">ИЛИ</div>
        <div  style="">
            <li>Добавьте контакт "номер телефона для телеграм"</li>
            <li>Перейти к своему боту - <a href="{url}" target="_blank">@{name}</a> и отправить команду /start. Ваш чат будет добавлен автоматически</li>
        </div>
        </div>
    `,
        phone_for_telegram_descriptions: `
            <br><h4>Указанный номер телефона используется только для регистрации в телеграм-боте</h4>
        `,
        types: {
            EMAIL: "Email",
            TELEGRAM_ID: "Telegram",
            PHONE_FOR_TELEGRAM: "Номер телефона для Telegram",
            PHONE: "Номер телефона",
        },
        channels_not_configured: {
            email: `Отправка через Email не настроена. Вы можете добавить контакт, но отправка заработает после настройки`,
            phone: `Номер телефона`,
            phone_for_telegram: `Номер телефона используемый для телеграм`,
            telegram: `Отправка через телеграм не настроена в системе.`,
        },
        tabs: {
            events_config: "Правила событий",
            actions_config: "Правила действий",
            email: "Канал отправки - Email",
            telegram: "Канал отправки - Telegram",
        },
        severities: {
            NOTIFICATION: "Notify",
            INFO: "Info",
            WARNING: "Warning",
            CRITICAL: "Critical",
        },
    },
    sfp_diag: "Диаг SFP",
    speed: 'Скорость',
    duplex: 'Duplex',
    uni_interfaces_status: "Статус интерфейсов UNI",
    pon_onts_down_history: "История падений ONT",
    pon_onts_configuration: "Конфигурация ONT",
    interface_counters: "Счетчики на интерфейсе",
    conf_status: "Состояние конфига",
    auth_method: "Метод авторизации",
    isolation: "Изоляция",
    attributes: "Аттрибуты",
    down_reason: "Причина падения",
    web: "Web",
    telnet: "Telnet",
    ssh: "SSH",
    system: "Система",
    ports: "Порты",
    diag: "Диагностика",
    sfp_info: " Информация SFP ",
    media_info: "Медиа информация",
    tx_bias: "TX смещение",
    serial_num: "Серийный номер",
    connector_type: "Тип разъема",
    fiber_type: "Тип волокна",
    eth_compliance_codes: "Коды соответствия ETH",
    baud_rate: "Скорость передачи данных",
    vendor_name: "Имя поставщика",
    part_number: "Номер части",
    networks_success_saved: "Сети успешно сохранены!",
    are_you_sure_to_delete_element: "Уверены, что хотите удалить элемент?",
    autodiscovery_networks_not_found: "Подсети автообнаружения не найдены",
    add_network: "Добавить подсеть",
    network_cidr: "CIDR",
    device_access: "Доступ к устройству",
    add_to_device_group: "Добавить новое устройство в группу",
    autodiscovery_device_access_is_required: "Выбор доступа к устройству для подсети {network}  обязательное",
    autodiscovery_device_group_is_required: "Выбор группы устройств для подсети {network} обязательное",
    config_autodiscovery: "Автообнаружение",
    welcome_to_nms: "Добро пожаловать!",
    welcome_message: `
<h3 class="text-info">Добро пожаловать в веб-панель <b>WildCore DMS</b>!</h3>
            <div>
              <h5 class="text-info">Оцените возможности диагностики и мониторинга оборудования</h5>
              <ol>
                <li>Добавьте свой первый доступ к оборудованию(community/login/password)
                  <a href="/management/device-access">здесь</a>
                </li>
                <li>Добавьте свое первое устроство
                  <a href="/management/device">здесь</a>
                </li>
              </ol>
            </div>
            <div>
              <h5 class="text-info">Настройте отправку уведомлений в Telegram или на электронную почту</h5>
              <ol>
                <li>Настройте способы отправки уведомлений -
                  <a href="/config/notifications">Настройки алертов</a>
                </li>
                <li>Добавьте свои контакты для получения уведомлений -
                  <a href="/account/settings">Настройки аккаунта</a>
                </li>
              </ol>
            </div>
            <div>
              <h5 class="text-info">Предоставьте доступ к wildcoreDMS коллегам</h5>
              <ol>
                <li>Создайте новую группу пользователей, предоставим необходимые им доступы -
                  <a href="/management/user-role">Группы</a>
                </li>
                <li>Добавьте новых пользователей -
                  <a href="/management/user">Пользователи</a>
                </li>
              </ol>
            </div>    
    `,
    here: "здесь",
    bind_status: "Bind-статус",
    olt_rx: "OLT RX",
    onu_statistic: "Статистика ОНУ (счетчики)",
    discards: "Discards",
    multicast: "Multicast",
    broadcast: "Broadcast",
    pon_onts_status: "Статус ОНУ",
    interface_descriptions: "Описания интерфесов",
    pon_onts_reasons: "Причины падения",
    pon_onts_vendor: "Vendor инфо",
    used_default_connections_parameters: "Используются параметры подключения по-умолчанию",
    change_default_connection_parameters: "Установить кастомные параметры подключения",
    set_default_connection_parameters: "Установить дефолтные параметры подключения",
    connection_connection_type: "Тип подключения",
    port: "Порт",
    timeout_sec: "Таймаут (сек)",
    snmp_timeout_sec: "SNMP таймаут (сек)",
    snmp_repeats: "SNMP repeats",
    labels: "Лейблы",
    ignore_events: "Игнорировать события",
    add_contact: "Добавить контакт",
    edit_contact: "Изменить контакт",
    are_you_sure_delete_contact: "Уверены, что хотите удалить контакт?",
    configured_sources_not_found: `
        Не найдены сконфигурированные источники<br>
        <small>Для управления контактами должен быть настроен хотя бы один источник</small>
    `,
    telegram_bot_configuration: "Конфигурация Telegram-бота",
    configuration_success_updated: "Конфигурация успешно обновлена",
    reset: "Сбросить",
    telegram_bot_name: "Имя бота",
    telegram_bot_api: "API-ключ",
    smtp_host: "SMTP хост",
    your_bot_username: "Имя вашего бота",
    your_api_key: "API-ключ вашего бота",
    smtp_port: "SMTP порт",
    smtp_secure: "Исп. безопасность",
    smtp_username: "SMTP имя пользователя",
    smtp_password: "SMTP пароль",
    from_name: "От имени",
    from_email: "От email",
    template_configuration: "Настройка шаблонов",
    alert_template: "Событие создано",
    alert_resolved: "Событие завершено",
    mock_object: "Объект события(используется в шаблоне)",
    smtp_configuration: "SMTP конфигурация",
    cpu_util_chart: "График загрузки CPU",
    memory_util_chart: "График загрузки памяти",
    disk_util_chart: "График загрузки диска",
    no_more_events: "Больше событий нет",
    no_events: "Нет событий",
    severities: "Severities",
    only_not_resolved: "Не решены",
    severity: "Severity",
    resolved_at: "Решено в",
    names: "Имена",
    created_at: "Создано в",
    not_resolved: "Не решен",
    no_device: "Нет устройства",
    are_you_sure_for_resolve_event: "Уверены, что хотите закрыть событие id={id}?",
    are_you_sure_for_resolve_all_filtered_events: "Уверены, что хотите закрыть все события по фильтру?",
    events_success_resolved: "Успешно закрыто {count} событий",
    resolve: "Решить (закрыть)",
    event_success_resolved: "Событие с id={id} успешно закрыто",
    resolve_displayed: "Закрыть все по фильтру",
    grafana: "Grafana",
    alertmanager: "Alertmanager",
    prometheus: "Prometheus",
    external_apps: "Внешние приложения",
    models: "Модели",
    device_group: "Группа устройства",
    icon: "Иконка",
    controller: "Контроллер",
    edit_model: "Изменение модели {name}",
    device_model_success_updated: "Модель {name} успешно обновлена",
    forbidden_resource: "Доступ запрещен",
    forbidden_resource_descr: "Обратитесь к администратору для получения доступа",
    agent_disabled: "Агент отключен или нет активной подписки. Поддержка - support@wildcore.tools",
    last_seen: "Посл. просмотр",
    no: "Нет",
    yes: "Да",
    limit_at: "Limit At",
    max_limit: "Max Limit",
    dynamic: "Динамический",
    target: "Target",
    reload: "Перезагрузить",
    traffic: "Трафик",
    last_link_up_time: "Посл. изменение статуса",
    interface_name: "Имя интерфейса",
    comment: "Коментарий",
    remote_address: "Удаленный адрес",
    remote_as: "Удаленная AS",
    local_address: "Локальный адрес",
    established: "Установленный",
    host_name: "Имя хоста",
    server: "Сервер",
    lease_time: "Время лиза",
    up: "Up",
    down: "Down",
    address: "Адрес",
    expired_at: "Истекает в",
    crontab: "Кронтаб",
    address_pool: "Имя пуля",
    count_arps: "ARPs<br><small>Количество</small>",
    bgp_sessions: "BPG-сессии<br><small>(Активно/Всего)</small>",
    interfaces_status: "Статусы интерфейсов<br><small>(Активно/Всего)</small>",
    interfaces_state: "Админ статусы интерфейсов<br><small>(Выключено/Всего)</small>",
    router_os: {
        tabs: {
            interfaces_list: "Интерфейсы",
            arps: "ARPs",
            bgp_sessions: "BGP-сессии",
            address_lists: "Адрес-листы",
            leases: "Лизы",
            dhcp_servers_list: "DHCP-сервера",
            simple_queue_list: "Шейпер",
        }
    },

    chart_translations: {
        Online: "Онлайн",
        Offline: "Офлайн",
        PowerOff: "Нет питания",
        LOS: "LOS",
        up: 'Работает',
        down: 'Лежит'
    },
    pinger_host_stat: "Статус хостов (ICMP)",
    optical_level_chart: "Уровни сигналов",
    displayed_with_step: "График с шагом",
    temp_chart: "График температуры",
    voltage_chart: "График напряжения",
    last_6_hours: "Посл. 6 часов",
    last_12_hours: "Посл. 12 часов",
    last_24_hours: "Посл. 24 часа",
    last_3_days: "Посл. 3 дня",
    last_7_days: "Посл. 7 дней",
    last_14_days: "Посл. 14 дней",
    last_21_days: "Посл. 21 дней",
    last_30_days: "Посл. 30 дней",
    choose_group_is_required: "Выбор группы устройства обязателен!",
    filter: 'Фильтр',
    expand_all: 'Раскрыть все',
    hide_all: 'Свернуть все',
    search_query: 'Поиск',
    device_models: 'Модели устройств',
    clear_filters: 'Очистить фильтр',
    group_success_created: 'Группа успешно создана!',
    group_success_updated: 'Группа успешно обновлена!',
    cleared: 'Очищено!',
    saved: 'Сохранено!',
    device_groups: "Группы устройств",
    device_group_management: "Управление группами устройств",
    create_new_group_btn: "Добавить новую",
    edit_group_with_id: "Изменить группу с ID {id}",
    checked: "Проверено",
    parameters_is_empty_are_you_sure: "Параметры не указаны! Уверены, что хотите сохранить?",
    fdb_history_card: "История FDB",
    history_not_found: "Нет записей",
    fdb_history: "История FDB - {interface}",
    active_now: "Активен",
    key: "Ключ",
    schedule_reports: "Отчеты планировщика",
    schedule_keys: "Ключи планировщика",
    hide_finished: "Спрятать завершенные",
    schedule: "Задача",
    started_at: "Запустился",
    finished_at: "Завершился",
    schedule_configuration: "Настройка планировщика",
    last_run: "Последний запуск",
    component: "Компонент",
    period: "Крон",
    command: "Команда",
    state: "Состояние(Вкл/Выкл)",
    system_cron: "СИСТЕМА",
    schedule_updated: "Планировщик обновлен",
    language: "Язык",
    your_login: "Логин",
    id: "ID",
    your_group: "Должность (группа)",
    type_more_symbols_for_search: "Введите от 3х символов для поиска...",
    choose_model_is_required: "Необходимо выбрать модель",
    choose_access_is_required: "Необходимо выбрать доступ",
    ip_address_not_valid: "Неккоректный IP адрес",
    default_value: "Значение по-умолчанию",
    system_configuration: "Конфигурация системы",
    error_load_fdb: "Ошибка загрузки FDB",
    pon_onts_serial: "Серийный номер",
    location: "Размещение",
    load_info_from_device: "Получить информацию с устройства",
    start_at: "Запустил",
    stop_at: "Завершил",
    unknown: "неизвестно",
    error_get_data_check_in_logs: "Ошибка получения данных. Смотрите в логи вызова оборудования для получения более детальной информации",
    on_device: "на устройстве",
    ont_ident: "Идентификатор ОНУ",
    mac_in_fdb: "MAC в FDB",
    device_is_offline_and_history_not_found: "Устройство не в сети, данные о устройстве недоступны",
    edit_port: "Конфигурирование порта {port}",
    interface_info: "О интерфейсе",
    admin_speed: "Админ. скорость",
    vlans_by_port: "Вланы на портах",
    rmon: "RMON",
    port_success_updated: "Порт {port} успешно изменен!",
    diag_check: "Проверить",
    link_info: "Инфо о линке",
    vlans: "Вланы",
    errors: "Ошибки",
    clear_counters: "Очистить счетчики",
    reboot: "Перезагрузка",
    are_you_sure_to_reboot_device: "Уверены, что хотите перезагрузить устройство?",
    are_you_sure_to_clear_counters: "Уверены, что хотите сбросить счетчики?",
    counters_success_reset: "Счетчики успешно сброшены!",
    device_success_sended_to_reboot: "Устройство успеншно отправлено в перезагрузку",
    fdb: "FDB",
    cable_diag: "Диаг. кабеля",
    port_num: "# порта",
    vlan: "Влан",
    counters: "Трафик",
    stat: "Статистика",
    uni_ports: "Порты ОНУ",
    are_you_sure_to_reset_onu: "Уверены, что хотите сбросить ОНУ?",
    are_you_sure_to_clear_counters_onu: "Уверены, что хотите очистить счетчики?",
    onu_success_reseted: "ОНУ успешно сброшена",
    onu_success_counters_cleared: "Счетчики успешно очищены",
    action_clear_counters_onu: "Очистить счетчики",
    action_reset_onu: "Сбросить ОНУ",
    admin_status: "Админ статус",
    mac_address: "MAC-адрес",
    info_status: "Информация о статусе",
    last_down_reason: "Последняя причина падения",
    online_since: "В онлайне",
    offline_since: "В офлайне",
    distance: "Расстояние",
    check_modules_status: "Статус инфо",
    pon_fdb: "FDB таблица",
    onu_status: "Состояние ОНУ",
    pon_onts_general_info: "Основная инфа о состоянии",
    pon_onts_mac_addr: "МАК-адрес ОНУ",
    vendor: "Производитель",
    hardware_ver: "Hardware ver.",
    software_ver: "Software ver.",
    optical_info: "Инфо о оптическом сигнале",
    rx: "RX",
    tx: "TX",
    temp: "Температура",
    voltage: "Напряжение",
    uni_port: "Порт в ОНУ",
    meta_information: "Мета информация",
    onts_info: "Информация о ОНУ",
    pon_onts_mac_addresses: "МАК-адреса ОНУ",
    pon_onts_optical: "Оптические сигналы ОНУ",
    pon_onts_status_detailed: "Состояние ОНУ",
    from_cache: "с кеша",
    big_notify: {
        device_is_offline: "<h3>Устройство не в сети!</h3><b>Отображена информация с истории</b>"
    },
    onu_success_sended_to_reboot: "ОНУ успешно отправлена в перезагрузку",
    account_settings: "Настройки аккаунта",
    logout: "Выйти из системы",
    extra_parameters: "Дополнительные параметры",
    onts_not_found_by_search: "Ону не найдены по запросу",
    server_errors: {
        SWC_ERROR_WORK_WITH_DEVICE: "Ошибка работы с оборудованием",
        GENERAL_SERVER_ERROR: "Возникла ошибка при работе с сервером, обратитесь к администратору",
        SERVER_ERROR: "Возникла непредвиденная ошибка, обратитесь к администратору",
        SWC_SNMP_EXCEPTION: "Ошибка опроса оборудования по SNMP",
        INSUFFICIENT_PRIVILEGES: "Недостаточно прав, обратитесь к администратору",
        DEVICE_ALREADY_EXIST: "Устройство уже существует",
    },
    device_success_deleted: "Устройство успешно удалено",
    search_placeholder: "Начните вводить для поиска...",
    go_to_device: "Перейти к устройству",
    my_account: "Мой аккаунт",
    parameters_success_saved: "Параметры успешно сохранены",
    account_settings_saved: "Настройки сохранены",
    reload_from_cache: "Перезагрузить",
    device_success_registered: "Устройство успешно зарегистрированно",
    cancel: "Отменить",
    service_profile: "Service профайл",
    config_state: "Сост. конфигурации",
    vport_mode: "Vport режим",
    register: "Зарегистрировать",
    action_dereg_onu: "Удалить ОНУ",
    fdb_is_empty: "FDB таблица пустая",
    are_you_sure_to_dereg_onu: "Уверены, что хотите удалить ОНУ?",
    are_you_sure_reboot_onu: "Уверены, что хотите перезагрузить ОНУ?",
    onu_success_registered: "ОНУ успешно зарегистрирована",
    ont_success_sended_to_reboot: "ОНУ успешно отправлена в перезагрузку",
    onu_success_deregistrated: "ОНУ успешно удалена",
    type: "Тип",
    onu_registration: {
        duplicate_key: "Дубликат ключа параметра с именем '{key}'",
        template_success_saved: "Шаблон успешно сохранен",
        twig_easy_doc: `
            <h4>Для генерации шаблона используется шаблонизатор Twig</h4>
            <span style="color: darkred; font-weight: bold">Прочтите <a href="https://twig.symfony.com/doc/3.x/templates.html" target="_blank">официальную документацию 'Twig for designers'</a>, что бы узнать возможности Twig. <br></span><br>
            <span style="color: gray">При внесении изменений, в окошке 'живой результат' будет отображаться сгенерированный сервером список команд  на основе шаблона, которые будут отправлены на оборудование в случае регистрации ОНУ. </span>
        `,
        select_from_predefined_description: `
            Укажите список параметров используя перенос строки 
        `,
        deprecated_unregistered_ont: "Незарегистрированная ОНУ (устаревшее)",
        unregistered_ont: "Незарегистрированная ОНУ",
        key_reg_description: `
            Ключ должен соответствовать регулярному выражению: ^[a-z][a-z_0-9]{1,}$.
            Обращение к значению к ключу должно быть по params.<key name>`,
        visible_if_description: `Условие на javascript для отображения`,
        select_from_predefined: "Выбрать из предустановленных",
        select_from_variable: "Выбрать из переменных",
        save_parameters: "Сохранить параметры",
        input_variable: "Поле для ввода с значением",
        input_string: "Поле для ввода",
        checkbox: "Чекбокс",
        all_device_types: "Всех типов",
        live_result: "Живой результат",
        add_parameter: "Добавить параметр",
        template: "Шаблон",
        visible_for: "Отображать для",
        source_param_key: "Источник значения",
        default_value: "Значение по-умолчанию",
        variants: "Варианты",
        parameter_can_be_empty: "Параметр может быть пустым",
        key: "Ключ",
        visible_name: "Отображаемое имя",
        parameter_type: "Тип параметра",
        template_variables: "Конфигурация параметров",
        predefined_variables_detailed: "Выберите устройство для получения переменных",
        profile_line: "Line профайл",
        profile_remote: "Remote профайл",
        epon_registration_template: "Шаблон регистрации для EPON",
        gpon_registration_template: "Шаблон регистрации для GPON",
        onu_number: "Номер ОНУ",
        input_string_description: `Позволяет добавить поле ввода при регистрации ОНУ, например для установки описания. Значение будет доступно по params.<key>`,
        input_string_variable: `Позволяет добавить поле ввода с значением подставленным из переменных. Это значение можно будет изменить при регистрации`,
        select_from_variable_description: `
            Позволяет добавить поле для выбора (select) из переменных.<br>
            Выбрать можно только массив из простых типов (строка, число).
         `,
        select_from_variables_list: "Список значений для выбора",
        variable_block: `
               <h4 style="color: darkred">Что бы управлять параметрами - выберите устройство и ОНУ <small>Устройство должно иметь незарегистрованное ОНУ</small></h4>
              
         `,
        parameters_descriptions: `Можно добавить параметры, которые станут доступны в шаблоне в поле <b><i>params.&lt;param key&gt</i></b> `,
        parameters_success_saved: "Параметры успешно сохранены",
        are_you_sure_to_delete: "Уверены, что хотите удалить данный параметр?",
        element_success_deleted: "Параметр успешно удален, сохраните результат",
        variables: "Переменные",
        templates: "Шаблоны",
        parameters: "Параметры",
        input_variable_description: "Позволяет устанавливать значение по-умолчанию для параметров",
        registration_form: "Форма ввода параметров",
        template_block: "Блок работы с шаблоном",
        save_template: "Сохранить шаблоны",
        template_compile_problem: "Ошибка компиляции шаблона: ",
        onu_form_registration: "Форма регистрации ОНУ",
        is_required: "Обязательный",

        visible_if: "Условие отображения",
    },
    field_is_required: "Обязательное поле",
    all: "Все",
    lang_short: 'ru',
    time_range: "Период времени",
    actions: "Действия",
    action: "Действие",
    loading: "Загрузка...",
    unregistered_onts: "Незарегистрированные ОНУ",
    deprecated_unregistered_onts: "Незарегистрированные ОНУ (устаревшее)",
    data: "Данные",
    time: "Время",
    message: "Сообщение",
    filters: "Фильтры",
    detected_unregistered_onts: "Обнаружено незарегистрированных ОНУ",
    log_statuses: {
        'null': "Не завершено",
        SUCCESS: "Успешно",
        FAILED: "ОШИБКА",
    },
    sorry_no_matching_options: "Вариантов не найдено",
    select_all: "Выбрать все",
    unselect_all: "Убрать все",
    user: "Пользователь",
    'module': "Модуль",
    modules: "Модули",
    arguments: "Аргументы",
    switcher_core_logs: "Логи вызова оборудования",
    log_device_calling: "Вызовы оборудования",
    log_actions: "Действия",
    log_actions_full: "Логи действий",
    system_info: "Информация о устройстве",
    add_device: "Добавить устройство",
    serial: "Серийный номер",
    updated_at: "Обновлено",
    access: "Доступ",
    interfaces: "Интерфейсы",
    back_to_interfaces: "Вернуться к интерфейсам",
    reload_info: "Обновить",
    action_reboot_onu: "Перезагрузить ОНУ",
    epon_onu_status: "Статус EPON ОНУ",
    gpon_onu_status: "Статус GPON ОНУ",
    fdb_table: "FDB таблица",
    logs: "Логи",
    signals: "Уровни сигналов",
    reg_time: "Регистрация",
    auth_time: "Авторизация",
    dereg_time: "Дерегистрация",
    reason: "Причина",
    technology: "Технология",
    interface: "Интерфейс",
    type_configured: "Type configured",
    type_reported: "Type reported",
    ver_firmware: "Ver firmware",
    ether_info: "Инфо о Ethernet-портах ОНУ",
    status_changed: "Кол. изменений",
    line_profile: "Line-профайл",
    admin_state: "Админ. состояние",
    online_duration: "В онлайне",
    model: "Модель",
    uptime: "Аптайм",
    found_onts: "Найдено ОНУ",
    devices_not_found: "Устройства не найдены",
    last_updated: "Обновлено",
    type_text_for_filter: "Начните вводить текст для фильтра",
    online: "Онлайн",
    offline: "Офлайн",
    power_off: "Отключена",
    search_device_placeholder: "Введите имя, IP или модель устройства...",
    dashboard: "Дашборд",
    devices: "Устройства",
    device_management: "Управление устройствами",
    accesses: "Доступы",
    users_management: "Пользователи",
    users: "Пользователи",
    groups: "Группы",
    config: "Конфигурация",
    devices_list: "Список устройств",
    no_results: "Нет результатов",
    count_devices: "Количество устройств",
    error_in_poller_by_day: "Ошибки сборщика<br><small>за сутки</small>",
    errors_switcher_core_by_day: "Ошибки работы с оборудованием<br><small>за сутки</small>",
    page_not_found: "СТРАНИЦА НЕ НАЙДЕНА!",
    page_not_found_descr: "ВЫ МОЖЕТЕ ВЕРНУТЬСЯ ДОМОЙ И ПОПРОБОВАТЬ ПОИСКАТЬ НУЖНУЮ СТРАНИЦУ ЕЩЕ РАЗ!",
    back_to_home: "Вернуться домой",
    something_wrong: "Возникли проблемы при работе с сервером, попробуйте перезагрузить страницу",
    try_reload_page: "Перезагрузить страницу",
    username: "Имя пользователя",
    password: "Пароль",
    confirm_password: "Подтвердите пароль",
    confirm_password_error: "Пароли не совпадают",
    password_instruction: "Оставьте поле ввода пароля пустым, если не хотите установить новый",
    sign_in: "Войти",
    login_or_password_incorrect: "Логин или пароль неверный",
    unknown_error: "Неизвестная ошибка",
    newer: "Никогда",
    delete_account: "Удалить аккаунт",
    dt_table: {
        count: "Отображено {from} до {to} из {count} записей|{count} записей|Одна запись",
        first: 'Первая',
        last: 'Последняя',
        filter: "Фильтр:",
        filterPlaceholder: "Поиск",
        limit: "Записей:",
        page: "Страница:",
        noResults: "Записей не найдено",
        filterBy: "Фильтр по {column}",
        loading: 'Загрузка...',
        defaultOption: 'Выбран {column}',
        columns: 'Столбцов'
    },
    personal_info: "Информация о пользователе",
    login: "Логин",
    name: "Имя",
    user_login: "Логин пользователя",
    user_name: "Имя пользователя",
    group: "Группы",
    status: "Статус",
    active_sessions: "Активные сессии",
    new_password: "Новый пароль",
    last_activity: "Последняя активность",
    save: 'Сохранить',
    create: 'Создать',
    user_list: 'Список пользователей',
    edit_user: 'Изменить пользователя %{login}',
    user_successful_updated: "Пользователь %{name} успешно обновлен",
    remote_addr: "IP адрес",
    device: "Устройство",
    close_session: "Закрыть сессию",
    session_closed: "Сессия %{id} успешно закрыта",
    are_you_sure_for_delete_user: "Вы уверены что хотите удалить пользователя? Это приведет к удалению всей информации о пользователе и его активности",
    are_you_sure_for_delete_group: "Вы уверены что хотите удалить группу? Это приведет к удалению всех пользователей состоящих в этой группе",
    are_you_sure_for_delete_device: "Вы уверены, что хотите удалить устройство?",
    group_successful_updated: "Группа %{name} успешно обновлена",
    group_successful_created: "Группа %{name} успешно создана",
    group_success_deleted: "Группа успешно удалена",
    delete_group: "Удалить группу",
    user_success_deleted: "Пользователь успешно удален",
    user_successful_created: "Пользователь %{login} успешно создан",
    create_new_user_btn: "Добавить пользователя",
    create_new_usergroup_btn: "Добавить группу",
    create_new_user: "Добавление нового пользователя",
    create_new_group: "Добавление новой группы",
    group_permissions_count: "Кол. разрешений",
    disabled: "Отключено",
    general_info: "Общая информация",
    enabled: "Включено",
    hidden: "Скрытый",
    group_privileges: "Привилегии группы",
    edit_group: "Изменение группы %{name}",
    display: "Отображение",
    description: "Описание",
    enter_name: "Введите имя",
    enter_description: "Введите описание",
    user_statuses: {
        enabled: 'Включен',
        disabled: 'Отключен'
    },
    hide_success: "Скрыть успешные",
    contain_text: "Содержит текст",
    edit: "Изменить",
    delete: "Удалить",

    // Access page
    device_access_management: 'Управление доступами',
    community: 'Комунити',
    create_new_access_btn: "Создать новый доступ",
    edit_access_with_id: "Изменение доступа #%{id}",
    close: "Закрыть",
    access_success_updated: "Доступ успешно обновлен",
    are_you_sure_for_delete_access: "Уверены, что хотите удалить доступ? Это приведет к удалению оборудования использующих данный доступ",
    access_success_deleted: "Доступ успешно удален",
    access_success_created: "Новый доступ успешно добавлен",
    create_new_access: "Внесение нового доступа",

    // Add device page
    create_new_device: "Внесение нового устройства",
    mac: "MAC",
    ip: "IP",
    device_success_updated: "Устройство %{name} успешно обновлено",
    device_success_created: "Устройство %{name} успешно создано",
    main: "Основное",
    edit_device: "Изменение устройства %{name}",
    device_id: "ID устройства",
    created: "Создано",
    updated: "Обновлено",
    device_view: "Просмотр устройства",
    device_edit: "Редактирование устройства",
    macros: {
        "item_filter": "Фильтр элементов",
        "item_filter_description": "Ограниченное выражение на JavaScript, которое фильтрует возможные варианты в условии. Условие должно возвращать логическое значение. Например, если элемент {id: 0, name: 'defaultProfile'}, и вы хотите его скрыть: item.id != 0",
        "model_vendor": "Фильтр поставщиков моделей",
        "item_name": "Название элемента",
        "item_name_description": "Ограниченное выражение на JavaScript, которое задает переменное имя для доступа к объектам в массиве, например, ${item.name} для доступа к [{item...}, {item_2...}]. 'item' - ключевое слово; имя может быть настраиваемым.",
        "execute_name": "Выполнение макроса '{name}'",
        "preview_commands": "Предварительный просмотр выполненных команд",
        "executed_output": "Результаты выполненных команд",
        "execution": "Выполнение",
        "show": "Макросы",
        "modal": "Макросы",
        "output": "Вывод",
        "execution_success": "Успешно выполнено",
        "edit_macros": "Редактирование макросов - \"%{name}\"",
        "add_new": "Добавить новый макрос",
        "params_empty": "Этот макрос не имеет параметров",
        "incorrect_value_in_fields": "Некоторые ошибки в полях",
        "success_updated": "Макрос успешно обновлен",
        "success_created": "Макрос успешно создан",
        "fail_updated": "Сбой обновления",
        "fail_created": "Сбой создания",
        "confirm_remove": "Вы уверены, что хотите удалить этот макрос?",
        "close": "Закрыть",
        "preview": "Предпросмотр",
        "remove": "Удалить",
        "cancel": "Отмена",
        "execute": "Выполнить",
        "config": "Макросы",
        "list": "Макросы",
        "add": "Добавить новый",
        "import": "Импорт",
        "export": "Экспорт",
        "edit": "Редактировать",
        "interfaces": "Интерфейсы",
        "common": "Общее",
        "form": "Макросы",
        "create": "Создать",
        "save": "Сохранить",
        "display_for": "Показывать для",
        "display_for_options": {
            "DEVICE": "Устройства",
            "PON": "PON порта",
            "PORT": "Порта",
            "ONU": "ONU"
        },
        "display_output": "Отображение вывода",
        "display_output_options": {
            "all": "Все команды",
            "last": "Последняя команда",
            "no": "Ничего"
        },
        "name": "Имя",
        "roles": "Роли",
        "user_roles": "Роли пользователя",
        "device": "Устройство",
        "models": "Модели",
        "description": "Описание",
        "regular_expr": "Регулярное выражение",
        "input_regular_expression": "Вы можете указать регулярное выражение (PCRE) для значения в поле ввода. Необязательно.",
        "duplicate_key": "Дублирование ключа с именем '{key}'",
        "template_success_saved": "Шаблон успешно сохранен",
        "twig_easy_doc": `
        <h4>Компиляция шаблонов предоставлена Twig.</h4>
        <span style="color: darkred; font-weight: bold">Читайте <a href="https://twig.symfony.com/doc/3.x/templates.html" target="_blank">официальную документацию 'Twig для дизайнеров'</a>. <br></span><br>
    `,
        "select_from_predefined_description": "Укажите предопределенные значения, по одному на строку.",
        "key_reg_description": "Должен соответствовать регулярному выражению: ^[a-z][a-z_0-9]{1,}$.",
        "visible_if_description": "Условие JavaScript для видимости свойства в выпадающем окне выполнения макроса. Вы также можете ссылаться на другие свойства из этого макроса, например, params.property === 'value'.",
        "select_from_predefined": "Выпадающий список из предопределенных",
        "select_from_variable": "Выпадающий список из переменных",
        "save_parameters": "Сохранить параметры",
        "input_variable": "Поле ввода со значением источника",
        "input_string": "Текстовое поле",
        "checkbox": "Флажок",
        "live_result": "Живой результат",
        "add_parameter": "Добавить параметр",
        "template": "Шаблон",
        "source_param_key": "Источник значения",
        "default_value": "Значение по умолчанию",
        "variants": "Варианты",
        "parameter_can_be_empty": "Необязательный",
        "key": "Свойство",
        "visible_name": "Отображаемое имя свойства",
        "parameter_type": "Тип параметра",
        "template_variables": "Конфигурация переменных",
        "predefined_variables_detailed": "Выберите устройство для загрузки переменных",
        "profile_line": "Профиль линии",
        "profile_remote": "Удаленный профиль",
        "onu_number": "Номер ONU",
        "input_string_description": ``,
        "input_string_variable": ``,
        "select_from_variable_description": ``,
        "select_from_variables_list": "Список предопределенных значений",
        "variable_block": `<h4 style="color: darkred">Выберите устройство и интерфейс, к которым будут применены параметры</h4>`,
        "parameters_descriptions": `Добавьте параметры ниже. К ним можно обратиться через точечную нотацию, например: {{ params.&lt;property&gt; }}`,
        "parameters_success_saved": "Параметры успешно сохранены",
        "are_you_sure_to_delete": "Вы уверены, что хотите удалить этот параметр?",
        "element_success_deleted": "Параметр успешно удален",
        "variables": "Переменные",
        "templates": "Шаблоны",
        "parameters": "Параметры",
        "input_variable_description": "Вы можете установить значение по умолчанию для этого свойства.",
        "parameters_form": "Форма параметров",
        "template_block": "Блок шаблона",
        "save_template": "Сохранить шаблон",
        "template_compile_problem": "Ошибка компиляции шаблона: ",
        "is_required": "Обязательно",
        "visible_if": "Условие видимости"
    },

    password_too_short: "Пароль должен быть не менее 8 символов",
    password_no_uppercase: "Пароль должен содержать хотя бы 1 большую букву",
    password_no_lowercase: "Пароль должен содержать хотя бы 1 маленькую букву",
    password_no_digit: "Пароль должен содержать хотя бы 1 цифру",
    password_no_special: "Пароль должен содержать хотя бы 1 специальный символ",
}
